import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VictoryAxis, VictoryLegend, VictoryLine } from 'victory';
import { RxVictoryChart } from './RxVictory';

interface ExerciseLog {
  timestamp: DateTime;
  duration_min: number;
  intensity: string;
}

const roundTimestampToMinute = (timestamp: number, minutes: number): number => {
  const ms = minutes * 60 * 1000;
  return Math.floor(timestamp / ms) * ms;
};

const getIntensityColor = (intensity: ExerciseLog['intensity']): string => {
  switch (intensity) {
    case 'low':
      // eslint-disable-next-line i18next/no-literal-string
      return '#66cc66';
    case 'high':
      // eslint-disable-next-line i18next/no-literal-string
      return '#ff6666';
    default:
      // eslint-disable-next-line i18next/no-literal-string
      return '#ffcc00';
  }
};

const ExerciseChart = (props: {
  width: number,
  height: number,
  xDomainMS: [number, number],
  xTickFrequencyHours: number,
  exerciseLogs: ExerciseLog[],
}) => {
  const { t } = useTranslation();

  const fixedY = props.height / 2;
  const hourInMS = 1000 * 60 * 60;

  const xTicks = _.range(
    props.xDomainMS[0] + hourInMS * 3,
    props.xDomainMS[1],
    hourInMS * props.xTickFrequencyHours,
  );

  const hiddenAxisStyle = {
    axis: { stroke: 'transparent' },
    ticks: { stroke: 'transparent' },
    tickLabels: { fill: 'transparent' },
  };

  return (
    <RxVictoryChart
      domain={{ x: props.xDomainMS, y: [0, 100] }}
      height={props.height}
      width={props.width}
      padding={{ top: 5, bottom: 30, left: 0, right: 25 }}
    >
      <VictoryAxis
        tickValues={xTicks}
        tickFormat={(x) =>
          x !== roundTimestampToMinute(x, 60)
            ? DateTime.fromSeconds(x / 1000).toFormat('h:mma')
            : DateTime.fromSeconds(x / 1000).toFormat('ha')}
        style={{
          ticks: { stroke: 'rgba(0,0,0,0.2)', size: 5 },
          tickLabels: {
            fontSize: 6,
            textAnchor: 'middle',
            fill: 'rgba(0,0,0,0.6)',
            padding: 5,
          },
          axisLabel: { fontSize: 4, padding: 22 },
        }}
      />
      <VictoryAxis dependentAxis style={hiddenAxisStyle} />
      {props.exerciseLogs.map((log, idx) => {
        const start = log.timestamp;
        const end = log.timestamp.plus({ minutes: log.duration_min });
        const segment = [
          { x: start, y: fixedY },
          { x: end, y: fixedY },
        ];
        return (
          <VictoryLine
            key={idx}
            data={segment}
            x="x"
            y="y"
            style={{ data: { stroke: getIntensityColor(log.intensity), strokeWidth: 3 } }}
          />
        );
      })}
      <VictoryLegend
        orientation="horizontal"
        gutter={20}
        style={{ labels: { fontSize: 6 } }}
        data={[
          { name: t('Low Intensity'), symbol: { fill: '#66cc66' } },
          { name: t('Medium Intensity'), symbol: { fill: '#ffcc00' } },
          { name: t('High Intensity'), symbol: { fill: '#ff6666' } },
        ]}
      />
    </RxVictoryChart>
  );
};

export default ExerciseChart;
