import { useQuery } from '@tanstack/react-query';
import { movanoApi } from '../api';
import { useStore } from '../context';

export const useMovanoSync = (opts: {
  start?: string | undefined,
  end?: string | undefined,
}) => {
  const { patient } = useStore();
  return useQuery({
    queryKey: ['movano', opts.start, opts.end],
    enabled: !!patient,
    queryFn: async () => {
      return await movanoApi.appIntegrationsMovanoMovanoEndpointsMovanoSyncData({
        patient_id: patient!.patient_id,
        MovanoSyncRequest: {
          start: opts.start,
          end: opts.end,
        },
      });
    },
  });
};
