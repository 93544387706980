/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MovanoIsLinkedResponse } from '../models';
// @ts-ignore
import { MovanoLinkRequest } from '../models';
// @ts-ignore
import { MovanoLinkResponse } from '../models';
// @ts-ignore
import { MovanoSyncRequest } from '../models';
/**
 * MovanoApi - axios parameter creator
 * @export
 */
export const MovanoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if patient is linked with Movano
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appIntegrationsMovanoMovanoEndpointsMovanoCheckLink: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appIntegrationsMovanoMovanoEndpointsMovanoCheckLink', 'patient_id', patient_id)
            const localVarPath = `/movano/{patient_id}/is_linked`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link patient with Movano
         * @param {number} patient_id 
         * @param {MovanoLinkRequest} MovanoLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient: async (patient_id: number, MovanoLinkRequest: MovanoLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient', 'patient_id', patient_id)
            // verify required parameter 'MovanoLinkRequest' is not null or undefined
            assertParamExists('appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient', 'MovanoLinkRequest', MovanoLinkRequest)
            const localVarPath = `/movano/{patient_id}/link`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(MovanoLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync data
         * @param {number} patient_id 
         * @param {MovanoSyncRequest} MovanoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appIntegrationsMovanoMovanoEndpointsMovanoSyncData: async (patient_id: number, MovanoSyncRequest: MovanoSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appIntegrationsMovanoMovanoEndpointsMovanoSyncData', 'patient_id', patient_id)
            // verify required parameter 'MovanoSyncRequest' is not null or undefined
            assertParamExists('appIntegrationsMovanoMovanoEndpointsMovanoSyncData', 'MovanoSyncRequest', MovanoSyncRequest)
            const localVarPath = `/movano/{patient_id}/sync`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(MovanoSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovanoApi - functional programming interface
 * @export
 */
export const MovanoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovanoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if patient is linked with Movano
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appIntegrationsMovanoMovanoEndpointsMovanoCheckLink(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MovanoIsLinkedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appIntegrationsMovanoMovanoEndpointsMovanoCheckLink(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Link patient with Movano
         * @param {number} patient_id 
         * @param {MovanoLinkRequest} MovanoLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient(patient_id: number, MovanoLinkRequest: MovanoLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MovanoLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient(patient_id, MovanoLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync data
         * @param {number} patient_id 
         * @param {MovanoSyncRequest} MovanoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appIntegrationsMovanoMovanoEndpointsMovanoSyncData(patient_id: number, MovanoSyncRequest: MovanoSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appIntegrationsMovanoMovanoEndpointsMovanoSyncData(patient_id, MovanoSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MovanoApi - factory interface
 * @export
 */
export const MovanoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovanoApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if patient is linked with Movano
         * @param {MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoCheckLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appIntegrationsMovanoMovanoEndpointsMovanoCheckLink(requestParameters: MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoCheckLinkRequest, options?: AxiosRequestConfig): AxiosPromise<MovanoIsLinkedResponse> {
            return localVarFp.appIntegrationsMovanoMovanoEndpointsMovanoCheckLink(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link patient with Movano
         * @param {MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoLinkPatientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient(requestParameters: MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoLinkPatientRequest, options?: AxiosRequestConfig): AxiosPromise<MovanoLinkResponse> {
            return localVarFp.appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient(requestParameters.patient_id, requestParameters.MovanoLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync data
         * @param {MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoSyncDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appIntegrationsMovanoMovanoEndpointsMovanoSyncData(requestParameters: MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoSyncDataRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appIntegrationsMovanoMovanoEndpointsMovanoSyncData(requestParameters.patient_id, requestParameters.MovanoSyncRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appIntegrationsMovanoMovanoEndpointsMovanoCheckLink operation in MovanoApi.
 * @export
 * @interface MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoCheckLinkRequest
 */
export interface MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoCheckLinkRequest {
    /**
     * 
     * @type {number}
     * @memberof MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoCheckLink
     */
    readonly patient_id: number
}

/**
 * Request parameters for appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient operation in MovanoApi.
 * @export
 * @interface MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoLinkPatientRequest
 */
export interface MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoLinkPatientRequest {
    /**
     * 
     * @type {number}
     * @memberof MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoLinkPatient
     */
    readonly patient_id: number

    /**
     * 
     * @type {MovanoLinkRequest}
     * @memberof MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoLinkPatient
     */
    readonly MovanoLinkRequest: MovanoLinkRequest
}

/**
 * Request parameters for appIntegrationsMovanoMovanoEndpointsMovanoSyncData operation in MovanoApi.
 * @export
 * @interface MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoSyncDataRequest
 */
export interface MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoSyncDataRequest {
    /**
     * 
     * @type {number}
     * @memberof MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoSyncData
     */
    readonly patient_id: number

    /**
     * 
     * @type {MovanoSyncRequest}
     * @memberof MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoSyncData
     */
    readonly MovanoSyncRequest: MovanoSyncRequest
}

/**
 * MovanoApi - object-oriented interface
 * @export
 * @class MovanoApi
 * @extends {BaseAPI}
 */
export class MovanoApi extends BaseAPI {
    /**
     * 
     * @summary Check if patient is linked with Movano
     * @param {MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoCheckLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovanoApi
     */
    public appIntegrationsMovanoMovanoEndpointsMovanoCheckLink(requestParameters: MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoCheckLinkRequest, options?: AxiosRequestConfig) {
        return MovanoApiFp(this.configuration).appIntegrationsMovanoMovanoEndpointsMovanoCheckLink(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link patient with Movano
     * @param {MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoLinkPatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovanoApi
     */
    public appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient(requestParameters: MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoLinkPatientRequest, options?: AxiosRequestConfig) {
        return MovanoApiFp(this.configuration).appIntegrationsMovanoMovanoEndpointsMovanoLinkPatient(requestParameters.patient_id, requestParameters.MovanoLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync data
     * @param {MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoSyncDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovanoApi
     */
    public appIntegrationsMovanoMovanoEndpointsMovanoSyncData(requestParameters: MovanoApiAppIntegrationsMovanoMovanoEndpointsMovanoSyncDataRequest, options?: AxiosRequestConfig) {
        return MovanoApiFp(this.configuration).appIntegrationsMovanoMovanoEndpointsMovanoSyncData(requestParameters.patient_id, requestParameters.MovanoSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

