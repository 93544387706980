import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from '../i18n';
import { titleCase } from '../utils/formatters';

interface MealCompositionTooltipProps {
  x: number;
  y: number;
  meal: {
    alcohol: number,
    carbs: number,
    fat: number,
    protein: number,
    meal_items: string[],
  };
  show: boolean;
}

const MealCompositionTooltip = (props: MealCompositionTooltipProps) => {
  const { x, y, meal, show } = props;
  const offset = 10;
  const tooltipRef = useRef(null);
  const [position, setPosition] = useState({ left: x + offset, top: y + offset });
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.getBoundingClientRect().height;
      let top = y + offset;
      const bottomThreshold = window.innerHeight - (tooltipHeight + offset);
      if (y > bottomThreshold) {
        top = y - tooltipHeight - offset;
      }
      setPosition({ left: x + offset, top });
    }
  }, [x, y, meal]);

  if (!show || !meal) {
    return null;
  }

  const alcoholTotal = String(Math.round(meal.alcohol));
  const carbsTotal = String(Math.round(meal.carbs));
  const fatTotal = String(Math.round(meal.fat));
  const proteinTotal = String(Math.round(meal.protein));

  const alcoholStr = t('Alcohol: {{alcoholTotal}} kcal', { alcoholTotal });
  const carbsStr = t('Carbs: {{carbsTotal}} kcal', { carbsTotal });
  const fatStr = t('Fat: {{fatTotal}} kcal', { fatTotal });
  const proteinStr = t('Protein: {{proteinTotal}} kcal', { proteinTotal });

  const mealItems = meal.meal_items;
  const maxChars = 30;
  let currentChars = mealItems[0].length;
  let i = 1;
  let mealItemStr = titleCase(mealItems[0]);
  while (i < mealItems.length) {
    currentChars += mealItems[i].length + 2;
    if (currentChars > maxChars) {
      break;
    }
    mealItemStr += ', ' + titleCase(mealItems[i]);
    ++i;
  }
  if (i < mealItems.length) {
    mealItemStr += ', ...';
  }

  return (
    <div
      ref={tooltipRef}
      style={{
        position: 'fixed',
        left: position.left,
        top: position.top,
        background: 'rgba(255, 255, 255, 0.8)',
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        zIndex: 1,
      }}
    >
      <div>
        <div style={{ fontWeight: 'bold' }}>
          {mealItemStr}
        </div>
        {meal.alcohol > 0 && (
          <div>
            {alcoholStr}
          </div>
        )}
        <div>
          {carbsStr}
        </div>
        <div>
          {fatStr}
        </div>
        <div>
          {proteinStr}
        </div>
      </div>
    </div>
  );
};

export default MealCompositionTooltip;
