import { useQueries, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { ANALYTICS_EVENTS, track } from '../analytics';
import { patientApi, reportingApi } from '../api';
import { PatientReportResponse } from '../api/generated/models';
import { ClinicalSummaryText } from '../components/ClinicalSummaryText';
import { ErrorFlagsModal, GenerateReport } from '../components/generateReport';
import { LoadingSpinner } from '../components/loadingSpinner';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import '../layout/layout.scss';
import { PatientService } from '../services/patient';
import './patient-reports.scss';

const PdfViewer = (props: { reportId: number }) => {
  const pdfRes = useAsyncResult<any>();
  const patientService = PatientService();

  useEffect(() => {
    pdfRes.clear();
    if (!props.reportId) {
      return;
    }

    pdfRes.bind(patientService.getPatientReportPdf(props.reportId));

    return () => {
      console.log('revoking object url');
      pdfRes.isDone && URL.revokeObjectURL(pdfRes.result);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportId]);

  if (pdfRes.isError) {
    return (
      <div style={{ color: 'red' }}>
        <Trans>Unable to load PDF file</Trans>: {'' + pdfRes.error}
      </div>
    );
  }

  if (!pdfRes.isDone) {
    return (
      <div>
        <Trans>Loading...</Trans>
      </div>
    );
  }

  return <object data={pdfRes.result} className="pdfViewer" type="application/pdf" />;
};

const usePatientReports = (
  patientId: number,
  patientClinicHospitalPairs: number[][] | undefined,
  setAllPatientReports: (reports: PatientReportResponse[]) => void,
) => {
  const patientService = PatientService();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchAllPatientReports = async () => {
    if (!patientClinicHospitalPairs?.length) {
      return;
    }
    setIsLoading(true);
    setIsError(false);
    try {
      const reportsArr: PatientReportResponse[] = [];
      await Promise.all(patientClinicHospitalPairs.map(async ([clinicId, hospitalId]) => {
        if (!clinicId) {
          return;
        }

        const reports = await patientService.getAllPatientReports(patientId, hospitalId, clinicId);
        if (!reports) {
          console.log('no reports');
          return;
        }
        reportsArr.push(...reports);
      }));

      reportsArr.sort((a, b) => moment(b.created_time).diff(moment(a.created_time)));
      setAllPatientReports(reportsArr);
    } catch (err) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchAllPatientReports,
    isLoading,
    isError,
  };
};

type ReportSectionType = 'clinician' | 'rxfood' | 'sent';

const Reports = (props) => {
  const { patient, clinician, allReports } = useStore();
  const { setAllPatientReports } = useStoreDispatch();
  const patientService = PatientService();
  const [expandedReports, setExpandedReports] = React.useState({});
  const clinicianGenReportsAvailable = React.useRef(false);
  const rxfoodGenReportsAvailable = React.useRef(false);
  const sentReportsAvailable = React.useRef(false);
  const [showReportGen, setReportGen] = useState(false);
  const [showErrorFlags, setShowErrorFlags] = useState(false);
  const [errorFlagReport, setErrorFlagReport] = useState<PatientReportResponse>(null);
  const [clinicId, setClinicId] = useState(0);
  const [hospitalId, setHospitalId] = useState(0);
  const [draftPending, setDraftPending] = useState(false);
  const [showClinicalSummaries, setShowClinicalSummaries] = useState({});
  const { t } = useTranslation();

  const patientClinicReq = useQuery(['patient-clinics-req', patient.patient_id, clinician.id], async () => {
    const response = await patientApi.appApiPatientGetClinics({
      patient_id: patient.patient_id,
    });
    return response.data;
  });
  const patientClinicData = patientClinicReq.data || [];

  // filters out clinics that the clinician is not associated with
  // and returns an array of [patientClinicId, hospitalId]
  const patientClinicHospitalPairs: number[][] | undefined = patientClinicData
    ?.map(
      (patientClinic) => {
        if (clinician.clinics.some(clinicianClinic => clinicianClinic.id === patientClinic.id)) {
          return [patientClinic.id, patientClinic.hospital_id];
        }
        return undefined;
      },
    )
    ?.filter(Boolean);

  const patientReportsQuery = usePatientReports(patient.patient_id, patientClinicHospitalPairs, setAllPatientReports);

  useEffect(() => {
    patientReportsQuery?.fetchAllPatientReports();
    setExpandedReports({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.patient_id, patientClinicHospitalPairs?.length]);

  useEffect(() => {
    if (patientClinicHospitalPairs?.[0]) {
      setClinicId(patientClinicHospitalPairs[0][0]);
      setHospitalId(patientClinicHospitalPairs[0][1]);
    }
  }, [patientClinicHospitalPairs]);

  function setPdfViewer(id, open) {
    const newDict = {};
    for (const x of allReports) {
      if (x.patient_report_id == id) {
        newDict[id] = open;
      } else {
        newDict[x.patient_report_id] = false;
      }
    }
    setExpandedReports(newDict);
  }

  const reportTypeDisplayText = {
    'default': t('Report'),
    'comparison_report': t('Comparison Report'),
    'cgm_report': t('CGM Report'),
    'rxfood': t('RxFood Report'),
  };

  const reportQueries = useQueries({
    queries: allReports.map((item, idx) => {
      return {
        queryKey: ['report-summary', item.patient_report_id, patient.patient_id, clinician.id],
        queryFn: async () => {
          const reportData = await reportingApi.appApiPatientReportsApiGetPatientReportData({
            patient_id: item.patient_id,
            hospital_id: item.hospital_id,
            clinic_id: item.clinic_id,
            patient_report_id: item.patient_report_id,
          });
          return { reportId: item.patient_report_id, reportData: reportData.data };
        },
      };
    }),
  });
  const reportClinicalSummary = reportQueries.map(q => ({
    isLoading: q.isLoading,
    isError: q.isError,
    isSuccess: q.isSuccess,
    reportId: q.data?.reportId,
    clinicalSummary: q.data?.reportData?.clinical_summary_statement,
  }));

  const errorMessageRegex = /custom|awaiting verification|with more than/;

  const processSendReport = async (report: PatientReportResponse, section: ReportSectionType) => {
    const isErrorFlag = report.error_flags.find(e => errorMessageRegex.test(e.message));
    if (isErrorFlag) {
      setShowErrorFlags(true);
      setErrorFlagReport(report);
      return;
    }
    const res = await patientService.setReportSent(
      hospitalId,
      clinicId,
      report.patient_id,
      report.patient_report_id,
    );
    if (res) {
      trackReportAction(patient.patient_id, report.patient_report_id, ANALYTICS_EVENTS.DRAFT_REPORT_SENT, section);
      patientReportsQuery?.fetchAllPatientReports();
    }
  };

  const processRegenerateReport = async (report: PatientReportResponse, section: ReportSectionType) => {
    const res = await patientService.regenerateReport(report.patient_report_id);
    if (res) {
      trackReportAction(
        patient.patient_id,
        report.patient_report_id,
        ANALYTICS_EVENTS.DRAFT_REPORT_REGENERATED,
        section,
      );
      patientReportsQuery?.fetchAllPatientReports();
    }
  };

  const processDeleteReport = async (report: PatientReportResponse, section: ReportSectionType) => {
    const res = await patientService.deleteReport(
      hospitalId,
      clinicId,
      patient.patient_id,
      report.patient_report_id,
    );
    if (res) {
      trackReportAction(patient.patient_id, report.patient_report_id, ANALYTICS_EVENTS.DRAFT_REPORT_DELETED, section);
      setAllPatientReports(
        allReports.filter((r) => r.patient_report_id !== report.patient_report_id),
      );
    }
  };

  const handleClinicalSummaryClick = (report: PatientReportResponse, section: ReportSectionType) => {
    const reportId = report.patient_report_id;
    setShowClinicalSummaries((prev) => ({ ...prev, [reportId]: !prev[reportId] }));
    trackReportAction(patient.patient_id, reportId, ANALYTICS_EVENTS.CLINICAL_SUMMARY_CLICKED, section);
  };

  const handlePdfViewClick = (report: PatientReportResponse, isOpen: boolean, section: ReportSectionType) => {
    setPdfViewer(report.patient_report_id, isOpen);
    trackReportAction(patient.patient_id, report.patient_report_id, ANALYTICS_EVENTS.REPORT_VIEWED, section);
  };

  const getReportSection = (report: PatientReportResponse): ReportSectionType | undefined => {
    if (!(report.report_type in reportTypeDisplayText)) {
      return;
    }
    if (report.status === 'sent') {
      return 'sent';
    }
    if (report.status === 'draft') {
      if (report.created_by_user_id === clinician.id) {
        return 'clinician';
      } else if (report.created_by_user_id === 1) { // TODO: check what id rxfood-gen reports are
        return 'rxfood';
      }
    }
  };

  const groupReports = useMemo(() =>
    allReports.reduce((acc, report) => {
      const section = getReportSection(report);
      if (section) {
        acc[section] = acc[section] || [];
        acc[section].push(report);
      }
      return acc;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, {} as Record<ReportSectionType, PatientReportResponse[]>), [allReports]);

  type ReportSectionFields = {
    key: ReportSectionType,
    title: string,
    label: string,
    showDraftPending: boolean,
    allowReportActions: boolean,
  };

  const reportsDef: Record<ReportSectionType, ReportSectionFields> = {
    clinician: {
      key: 'clinician' as const,
      title: t('Clinician Generated Reports'),
      label: t('clinician generated'),
      showDraftPending: true,
      allowReportActions: clinician?.is_report_admin || clinician?.flags?.clinician_can_generate_reports,
    },
    rxfood: {
      key: 'rxfood' as const,
      title: t('RxFood Generated Reports'),
      label: t('RxFood generated'),
      showDraftPending: false,
      allowReportActions: clinician?.is_report_admin || clinician?.flags?.clinician_can_generate_reports,
    },
    sent: {
      key: 'sent' as const,
      title: t('Sent Reports'),
      label: t('sent'),
      showDraftPending: false,
      allowReportActions: false,
    },
  };

  return (
    <div>
      <div style={{ marginBottom: '100px' }}>
        <br />
        <div className="sectionHeader">
          <div>
            <p className="goalDetail">
              <Trans>Patient Reports</Trans>
            </p>
            <div>
              <Trans>Latest reports first, click to view PDF</Trans>
            </div>
          </div>
          {(clinician.is_report_admin || clinician.is_report_previewer
            || clinician?.flags?.clinician_can_generate_reports) && (
            <Button onClick={() => setReportGen(true)}>
              <Trans>Generate New Report</Trans>
            </Button>
          )}
        </div>

        <div className="mt-4 mb-1" style={{ fontSize: '14px' }}>
          <Trans>
            If a report is missing you can contact support (
            {/**/}
            <a
              href="mailto:support@rxfood.co?bcc=dlichti@rxfood.co&subject=Clinician Portal Report Missing"
              target="_blank"
              className="emailLink"
            >
              support@rxfood.co<i className="fas fa-external-link" />
            </a>
            {/**/}
            ). If it is urgent (i.e. needed during the current day) you can also call 1-888-998-6181.'
          </Trans>
        </div>

        {patientReportsQuery.isError || patientClinicReq.isError
          ? (
            <div>
              <h6>
                <Trans>Error loading patient reports</Trans>
              </h6>
            </div>
          )
          : (
            <>
              {Object.values(reportsDef).map((sectionDef) => (
                <div key={sectionDef.key}>
                  <br />
                  <h6>{sectionDef.title}</h6>
                  {sectionDef.showDraftPending && draftPending && (
                    <div>
                      <Trans>Draft pending</Trans>
                    </div>
                  )}
                  {!groupReports[sectionDef.key]?.length && (
                    <div>
                      <div>{t('No {{section}} reports available.', { section: sectionDef.label })}</div>
                    </div>
                  )}
                  {(groupReports[sectionDef.key] || []).map((item, idx) => {
                    const pdfOpened = expandedReports[item.patient_report_id];
                    const hoverText = t('Report Created {{createdTime}}; Updated {{updatedTime}}', {
                      createdTime: moment(item.created_time).format('YYYY-MM-DD HH:MM'),
                      updatedTime: moment(item.updated_time).format('YYYY-MM-DD HH:MM'),
                    });

                    return (
                      <div
                        key={idx}
                        id={'' + item.patient_report_id}
                        style={{ paddingBottom: '0.5rem', cursor: 'pointer' }}
                      >
                        <label
                          onClick={() => handlePdfViewClick(item, !pdfOpened, 'clinician')}
                          title={hoverText}
                          // eslint-disable-next-line i18next/no-literal-string
                        >
                          [{pdfOpened
                            ? '-'
                            // eslint-disable-next-line i18next/no-literal-string
                            : '+'}]{' '}
                          <span className="reportBtnLink">
                            {reportTypeDisplayText[item.report_type]} - {moment(item.date_since).format('YYYY-MM-DD')}
                            {' '}
                            <Trans>to</Trans> {moment(item.date_until).format('YYYY-MM-DD')}
                          </span>
                        </label>
                        {sectionDef.allowReportActions
                          ? (
                            <>
                              <i
                                className="fas fa-envelope"
                                style={{ width: '36px', paddingLeft: '12px', color: '#909090' }}
                                onClick={async () => await processSendReport(item, sectionDef.key)}
                                title={t('Send report')}
                              />
                              <i
                                className="fas fa-redo"
                                style={{ width: '36px', paddingLeft: '6px', color: '#909090' }}
                                onClick={async () => await processRegenerateReport(item, sectionDef.key)}
                                title={t('Regenerate report')}
                              />
                              <i
                                className="fas fa-trash-alt"
                                style={{ width: '36px', paddingLeft: '3px', color: '#909090' }}
                                onClick={async () => await processDeleteReport(item, sectionDef.key)}
                                title={t('Delete report')}
                              />
                            </>
                          )
                          : <span style={{ width: '36px', paddingLeft: '12px', color: '#909090' }} />}

                        {reportClinicalSummary.every(r => r.isSuccess) && (
                          <button
                            onClick={() => handleClinicalSummaryClick(item, sectionDef.key)}
                          >
                            <Trans>Clinical Summary</Trans>
                          </button>
                        )}
                        {!!item.dates_exclude?.length && (
                          <div style={{ marginTop: '-5px', marginLeft: '25px', fontSize: '12px' }}>
                            {' '}
                            <Trans>excluding</Trans>{'  '}{item.dates_exclude.map((date, idx) => (
                              <span key={idx}>
                                {moment(date).format('YYYY-MM-DD')}
                                {idx < item.dates_exclude.length - 1 ? ', ' : ''}
                              </span>
                            ))}
                          </div>
                        )}

                        {showClinicalSummaries[item.patient_report_id] && reportQueries.every(r => r.isSuccess) && (
                          <ClinicalSummaryText
                            reportClinicalSummary={reportClinicalSummary.find(r =>
                              r.reportId === item.patient_report_id
                            )?.clinicalSummary}
                            t={t}
                          />
                        )}

                        {pdfOpened && <PdfViewer reportId={item.patient_report_id} />}
                      </div>
                    );
                  })}
                </div>
              ))}
            </>
          )}
      </div>

      <GenerateReport
        show={showReportGen}
        toggle={() => {
          setReportGen(!showReportGen);
        }}
        draftPending={draftPending}
        setDraftPending={setDraftPending}
      />
      {errorFlagReport && (
        <ErrorFlagsModal
          show={showErrorFlags}
          toggle={() => setShowErrorFlags(!showErrorFlags)}
          report={errorFlagReport}
          errorMessageRegex={errorMessageRegex}
          fetchReports={patientReportsQuery?.fetchAllPatientReports}
        />
      )}
    </div>
  );
};

const trackReportAction = (
  patientId: number,
  reportId: number,
  action: typeof ANALYTICS_EVENTS[keyof typeof ANALYTICS_EVENTS],
  section: ReportSectionType,
) => {
  track(action, {
    'Patient ID': patientId,
    'Report ID': reportId,
    'Report Section': section === 'clinician'
      ? 'Clinician Generated'
      : section === 'rxfood'
      ? 'RxFood Generated'
      : 'Sent',
  });
};

export default Reports;
