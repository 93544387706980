import emailValidator from 'email-validator';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { validate } from '../components/formValidator';
import { Input } from '../components/input';
import { config } from '../config';
import { useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import logo from '../logo.svg';
import { AuthService } from '../services/auth';
import './login.scss';
import { ANALYTICS_EVENTS, track } from '../analytics';
import { defaultFiltersState, usePatientListFilters } from './patientlist';

const LANG_TO_SHOW: Record<string, { label: string, paramValue: string }> = {
  en: {
    label: 'Français',
    paramValue: 'fr',
  },
  fr: {
    label: 'English',
    paramValue: 'en',
  },
};

const ErrorLabel = (props) => {
  return (
    <p className="errorMessage errorLabel margin-bot-m" style={{ textTransform: 'none', fontSize: '12px' }}>
      {props.message}
    </p>
  );
};

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const Login = withRouter(props => {
  const queryParams = useQuery();
  const secureToken = queryParams.get('secureToken');
  const { t, i18n } = useTranslation();

  const { reset } = useStoreDispatch();
  const authService = AuthService();
  const [checkingMagicLink, setCheckingMagicLink] = useState(secureToken !== null);
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [emailError, setEmailError] = useState('');
  const [otpRequired, setOtpRequired] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [authError, setAuthError] = useState('');
  const [loginerror, setLoginError] = useState('');
  const [password, setPassword] = useState('');

  const validators = {
    email: () => {
      if (!email) {
        setEmailError(t('Please enter your email address'));
        return false;
      }
      if (!emailValidator.validate(email)) {
        setEmailError(t("oops! that's not a valid email"));
        return false;
      }
      setEmailError('');
      return true;
    },
    password: () => {
      if (!password) {
        setPasswordError(t('Please enter a password'));
        return false;
      }
      setPasswordError('');
      return true;
    },
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    setAuthError('');
    if (!validate(validators.email, validators.password)) {
      return false;
    }
    const authResult = await authService.authenticate(email, password, otp);

    if (authResult.success) {
      usePatientListFilters.setState(defaultFiltersState);
      props.history.push('/patients');
      track(ANALYTICS_EVENTS.LOGIN);
    } else if (authResult.reason === 'mfa_token_required') {
      setOtpRequired(true);
    } else if (authResult.reason === 'token_invalid') {
      setAuthError(t('Token invalid. Please try again.'));
    } else if (authResult.reason === 'inactive_user') {
      setAuthError(t('Authentication failed. User is inactive'));
    } else {
      setAuthError(t('Authentication failed. Please check your email address and password.'));
      reset();
    }
  };

  const onFormSubmit = e => {
    e.preventDefault();
    handleLogin();
  };

  const checkSecureToken = useCallback(async (secureToken) => {
    const { success } = await authService.authenticateWithSecureToken(secureToken);

    if (success) {
      props.history.push('/patients');
    } else {
      setCheckingMagicLink(false);
    }
  }, [authService, props.history]);

  useEffect(() => {
    if (!secureToken) {
      return;
    }
    checkSecureToken(secureToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secureToken]);

  return (
    <Container>
      <Row>
        <Col />
        <Col>
          <img src={logo} className="App-logo" alt="logo" style={{ marginBottom: 40 }} />
          {checkingMagicLink
            ? (
              <Trans>
                Loading...
              </Trans>
            )
            : (
              <Form onSubmit={onFormSubmit}>
                {otpRequired
                  ? (
                    <>
                      <p className="margin-bot-l">
                        <Trans>Please enter the 6-digit code sent to your email address.</Trans>
                      </p>
                      <OtpInput
                        value={otp}
                        onChange={setOTP}
                        numInputs={6}
                        shouldAutoFocus={true}
                        separator={<span>&nbsp;</span>}
                        isInputNum={true}
                        inputStyle={{
                          width: '3rem',
                          padding: '0.5rem',
                        }}
                      />
                    </>
                  )
                  : (
                    <>
                      <Input
                        controlId="formBasicEmail"
                        type="email"
                        label={t('EMAIL')}
                        background
                        size="lg"
                        error={emailError}
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={() => validate(validators.email)}
                      />
                      <Input
                        controlId="formBasicPassword"
                        type="password"
                        label={t('PASSWORD')}
                        background
                        size="lg"
                        error={passwordError}
                        value={password}
                        onChange={handlePasswordChange}
                        onBlur={() => validate(validators.password)}
                      />
                    </>
                  )}
                <ErrorLabel message={authError} />
                <p className="margin-bot-l">
                  <Link className="btn-link" to="/forgotpassword">
                    <Trans>Forgot password</Trans>
                  </Link>
                </p>
                <p className="margin-bot-l">
                  <Button type="submit" variant="primary" block className="margin-bot-l">
                    <Trans>Login</Trans>
                  </Button>
                </p>
              </Form>
            )}
        </Col>
        <Col />
      </Row>
      <Row>
        <Col>
          <p className="text-center">
            <Trans>
              By logging in you agree to RxFood's{' '}
              <Link className="btn-link" to="https://rxfood.co/privacy-policy">Privacy policy</Link> and{' '}
              <Link className="btn-link" to="https://rxfood.co/terms-and-conditions">terms & conditions</Link>
            </Trans>
          </p>
          <p className="text-center">
            {config.RELEASE}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="text-center">
            <a
              className="btn-link"
              onClick={(e) => {
                e.preventDefault();
                i18n.changeLanguage(LANG_TO_SHOW[i18n.language].paramValue);
                const url = new URL(window.location.href);
                url.searchParams.delete('lang');
                window.history.replaceState({}, '', url);
              }}
            >
              {LANG_TO_SHOW[i18n.language].label}
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
});

export default Login;
