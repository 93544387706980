import _axios from 'axios';
import { useEffect } from 'react';
import { useAnalyticsUserIdentity } from '../analytics';
import { config } from '../config';
import { useStore, useStoreDispatch } from '../context';
import { Configuration, MovanoApi, PatientAppDataApi } from './generated';
import { CgmApi } from './generated/apis/cgm-api';
import { ClinicianApi } from './generated/apis/clinician-api';
import { ClinicianTipApi } from './generated/apis/clinician-tip-api';
import { FoodApi } from './generated/apis/food-api';
import { GoalApi } from './generated/apis/goal-api';
import { HospitalApi } from './generated/apis/hospital-api';
import { MealApi } from './generated/apis/meal-api';
import { OauthApi } from './generated/apis/oauth-api';
import { OpenaiApi } from './generated/apis/openai-api';
import { PatientApi } from './generated/apis/patient-api';
import { PatientReportApi } from './generated/apis/patient-report-api';
import { RecipesApi } from './generated/apis/recipes-api';
import { ReportingApi } from './generated/apis/reporting-api';
import { SubscriptionApi } from './generated/apis/subscription-api';
import { TasksApi } from './generated/apis/tasks-api';
import { UserApi } from './generated/apis/user-api';
import { UserSurveysApi } from './generated/apis/user-surveys-api';
import { WaterApi } from './generated/apis/water-api';

declare global {
  function fetch(url: URL | string, init?: RequestInit): Promise<Response>;
}

export const useAuthRedirectOn401Error = () => {
  const { reset } = useStoreDispatch();
  const axios = _axios;

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const shouldReThrowError = !axios.isAxiosError(error)
          || error.response?.status != 401
          || !apiConfig.accessToken
          || error.config.url?.includes('/oauth/authorize');
        if (shouldReThrowError) {
          throw error;
        }

        console.warn('Got HTTP 401, logging out and redirecting to login page', error);
        reset();
        window.location.href = '/login';
        throw error;
      },
    );
    return () => axios.interceptors.response.eject(interceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const ApiAuthSync = () => {
  const { token, clinician } = useStore();
  useAuthRedirectOn401Error();
  useAnalyticsUserIdentity(clinician);
  useEffect(() => {
    apiConfig.accessToken = token;
  }, [token]);
  return null;
};

export const hostUrl = config.API_URL;
export const apiConfig = new Configuration({
  basePath: config.API_URL + '/api',
});
export const cgmApi = new CgmApi(apiConfig);
export const patientReportApi = new PatientReportApi(apiConfig);
export const patientApi = new PatientApi(apiConfig);
export const clinicianApi = new ClinicianApi(apiConfig);
export const recipeApi = new RecipesApi(apiConfig);
export const reportingApi = new ReportingApi(apiConfig);
export const userApi = new UserApi(apiConfig);
export const userSurveysApi = new UserSurveysApi(apiConfig);
export const subscriptionApi = new SubscriptionApi(apiConfig);
export const goalApi = new GoalApi(apiConfig);
export const hospitalApi = new HospitalApi(apiConfig);
export const mealApi = new MealApi(apiConfig);
export const openAIApi = new OpenaiApi(apiConfig);
export const oauthApi = new OauthApi(apiConfig);
export const waterApi = new WaterApi(apiConfig);
export const foodApi = new FoodApi(apiConfig);
export const clinicianTipApi = new ClinicianTipApi(apiConfig);
export const tasksApi = new TasksApi(apiConfig);
export const patientAppDataApi = new PatientAppDataApi(apiConfig);
export const movanoApi = new MovanoApi(apiConfig);
