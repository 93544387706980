import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from '../i18n';

export const PageNotFound = () => {
  return (
    <Container>
      <Row>
        <Col />
        <Col xs={6} className="text-center">
          <h1 className="margin-bot-l">
            <Trans>Oops! Looks like the page you are looking for does not exist.</Trans>
          </h1>
          <p>
            <Link className="btn-link" to="/login">
              <Trans>login</Trans>
            </Link>
          </p>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};
