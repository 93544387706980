import { useQueries, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { reportingApi } from '../api';
import { PatientHealthDataTableResponse, PatientHealthDataTableResponseDataCols } from '../api/generated/models';
import {
  HR_CHART_Y_AXIS_MAX,
  HR_CHART_Y_AXIS_MIN,
  SPO2_CHART_Y_AXIS_MAX,
  SPO2_CHART_Y_AXIS_MIN,
} from '../components/HRChart';
import { useStore } from '../context';
import { MealName } from '../utils/formatDate';

// TODO: map each category to valid fields, aggregation functions, grouper
export type HealthDataTableCategory =
  | 'nutrients'
  | 'steps'
  | 'exercise'
  | 'medication'
  | 'emotion'
  | 'sleep_session'
  | 'sleep_stages'
  | 'heart_rate'
  | 'spo2';
export type HealthDataTableAggregationFn = 'sum' | 'avg' | 'min' | 'max' | 'count' | 'p25' | 'p50' | 'p75';
export type HealthDataTableGrouper = 'hour' | 'day' | 'week' | 'month' | 'year' | 'meal_id' | 'meal_name';
export type TimestampFilterKey = 'gte' | 'lte' | 'gt' | 'lt' | 'eq' | 'ne';
export interface HeartRateDataPoint {
  x: number;
  p25: number;
  p50: number;
  p75: number;
}

export interface OxygenDataPoint {
  x: number;
  spo2: number;
  mappedSpo2: number;
}

export const useProcessedHeartRateData = (
  patientData: PatientHealthDataTableResponse,
): HeartRateDataPoint[] => {
  if (!patientData?.data_cols?.group || !patientData.data_cols.heart_rate_bpm?.p50) {
    return [];
  }

  const { group, heart_rate_bpm } = patientData.data_cols;

  return group
    .map((timestamp, index) => {
      const p50 = heart_rate_bpm.p50[index];

      if (!p50 || p50 <= 0) {
        return null;
      }

      let p25 = heart_rate_bpm.p25?.[index] ?? p50;
      if (p25 === 0) {
        p25 = p50;
      }

      let p75 = heart_rate_bpm.p75?.[index] ?? p50;
      if (p75 === 0) {
        p75 = p50;
      }

      const unixTimestamp = DateTime.fromISO(timestamp)
        .toMillis();

      return { x: unixTimestamp, p25, p50, p75 };
    })
    .filter((d): d is HeartRateDataPoint => d !== null);
};

export const useProcessedOxygenData = (
  patientData: PatientHealthDataTableResponse,
): OxygenDataPoint[] => {
  if (!patientData?.data_cols?.group || !patientData.data_cols.spo2_pct?.p50) {
    return [];
  }

  const { group, spo2_pct } = patientData.data_cols;

  return group
    .map((timestamp, index) => {
      const spo2 = spo2_pct.p50?.[index];

      if (spo2 === undefined || spo2 === null || spo2 <= 0) {
        return null;
      }

      const unixTimestamp = DateTime.fromISO(timestamp)
        .toMillis();

      return {
        x: unixTimestamp,
        spo2,
        mappedSpo2: ((spo2 - SPO2_CHART_Y_AXIS_MIN)
              / (SPO2_CHART_Y_AXIS_MAX - SPO2_CHART_Y_AXIS_MIN))
            * (HR_CHART_Y_AXIS_MAX - HR_CHART_Y_AXIS_MIN) + HR_CHART_Y_AXIS_MIN,
      };
    })
    .filter((entry): entry is OxygenDataPoint => entry !== null);
};

export const HEALTH_DATA_TABLE_QUERY_KEY = 'health-data-table';

export const useHealthDataTable = (opts: {
  category: HealthDataTableCategory,
  field?: string | undefined,
  aggregator?: HealthDataTableAggregationFn | HealthDataTableAggregationFn[] | undefined,
  grouper?: HealthDataTableGrouper | undefined,
  timestampFilter?: Partial<Record<TimestampFilterKey, string>> | undefined,
  enabled?: boolean | undefined,
}) => {
  const { patient } = useStore();

  return useQuery({
    queryKey: [
      HEALTH_DATA_TABLE_QUERY_KEY,
      opts.category,
      opts.field,
      opts.aggregator,
      opts.grouper,
      opts.timestampFilter,
    ],
    enabled: !!patient && !!opts.category && !!opts.enabled,
    queryFn: async () => {
      const res = await reportingApi.appApiHealthDataTableGetPatientHealthData({
        patient_id: patient!.patient_id,
        category: opts.category,
        field: opts.field,
        aggregation_fn: opts.aggregator
          ? JSON.stringify(Array.isArray(opts.aggregator) ? opts.aggregator : [opts.aggregator]) as any
          : undefined,
        grouper: opts.grouper,
        filter: JSON.stringify({ timestamp: opts.timestampFilter }),
      });
      return res.data;
    },
  });
};

export const useHealthDataTableAggQueries = (opts: {
  category: HealthDataTableCategory,
  fields: string[],
  aggregator: HealthDataTableAggregationFn,
  grouper: HealthDataTableGrouper | undefined,
  timestampFilter: Partial<Record<TimestampFilterKey, string>> | undefined,
}) => {
  const { patient } = useStore();

  return useQueries({
    queries: (opts.fields || []).map(field => ({
      queryKey: [
        HEALTH_DATA_TABLE_QUERY_KEY,
        opts.category,
        field,
        opts.aggregator,
        opts.grouper,
        opts.timestampFilter,
      ],
      enabled: !!patient && !!opts.category && !!field && !!opts.aggregator && !!opts.grouper,
      queryFn: async () => {
        const res = await reportingApi.appApiHealthDataTableGetPatientHealthData({
          patient_id: patient!.patient_id,
          category: opts.category,
          field: field,
          aggregation_fn: Array.isArray(opts.aggregator) ? opts.aggregator : [opts.aggregator],
          grouper: opts.grouper,
          filter: JSON.stringify({ timestamp: opts.timestampFilter }),
        });
        return res.data;
      },
    })),
  });
};

export type HealthDataColsByGroupParam =
  | (Record<'group', string[]> & Record<string, Record<string, number[]>>)
  | undefined;

/**
 * Generic helper function to get a value from health data columns by group index
 */
const getValueFromDataColsByIndex = <T extends string | number>(opts: {
  healthDataCols:
    | (Record<'group', T[]> & Record<string, Record<string, number[]>>)
    | undefined
    | PatientHealthDataTableResponseDataCols,
  field: string,
  aggregator: HealthDataTableAggregationFn,
  groupValue: T,
}): number => {
  if (!opts.healthDataCols || !opts.healthDataCols.group) {
    return 0;
  }
  const idx = opts.healthDataCols.group.findIndex(c => c === opts.groupValue);
  if (idx === -1) {
    return 0;
  }
  const targetData = opts.healthDataCols[opts.field];
  const value = targetData?.[opts.aggregator]?.[idx];
  return isNaN(value) ? 0 : value;
};

/**
 * Helper function to get the value from health data columns for a specific date
 *
 * @param opts.healthDataCols - The health data columns object containing grouped data
 * @param opts.isoDate - The ISO date string to look up
 * @param opts.healthTargetName - The name of the health target to get data for
 * @param opts.aggregator - The aggregation function used (e.g. 'sum', 'avg')
 * @returns The numeric value for the given date and target, or 0 if not found/invalid
 * @remarks Requires the grouper to be time-based (e.g. 'day', 'week', 'month', 'year')
 */
export const getValueFromDataColsByGroupParam = (opts: {
  healthDataCols: HealthDataColsByGroupParam | PatientHealthDataTableResponseDataCols,
  field: string,
  aggregator: HealthDataTableAggregationFn,
  groupParam: string | number | MealName,
}): number => {
  return getValueFromDataColsByIndex<string | number | MealName>({
    healthDataCols: opts.healthDataCols,
    field: opts.field,
    aggregator: opts.aggregator,
    groupValue: opts.groupParam,
  });
};
