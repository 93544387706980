import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VictoryAxis, VictoryLegend, VictoryScatter } from 'victory';
import { reportingApi } from '../api';
import { useStore } from '../context';
import { MACRO_NUTRIENT_QUERY_KEY } from '../pages/patient-logV2';
import MealCompositionMarker, { MealCompositionMarkerData } from './MealCompositionMarker';
import MealCompositionTooltip from './MealCompositionTooltip';
import { RxVictoryChart } from './RxVictory';

interface MealLog {
  meal_id: number;
  meal_name: string;
  timestamp: DateTime;
  meal_items: string[];
}

interface FilteredMeal extends MealLog {
  alcohol: number;
  carbs: number;
  fat: number;
  protein: number;
  carbs_g: number;
}

const roundTimestampToMinute = (timestamp: number, toMinutes: number) => {
  const ms = toMinutes * 60 * 1000;
  return Math.floor(timestamp / ms) * ms;
};

const CustomMealMarker = (props) => {
  const { datum, x, y, height, maxMealGrams, onMouseMove, onMouseEnter, onMouseLeave, onUpdateTooltip } = props;

  return (
    <g transform={`translate(${x}, ${y})`}>
      <MealCompositionMarker
        meal={datum.meal}
        graphHeight={height}
        maxMealGrams={maxMealGrams}
        onMouseMove={onMouseMove}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onUpdateTooltip={onUpdateTooltip}
      />
    </g>
  );
};

const segmentMeals = (meals: FilteredMeal[], chainMeals = false, windowMilliseconds = 3600000) => {
  meals.sort((a, b) => a.timestamp.toMillis() - b.timestamp.toMillis());
  const results = [];
  let current = null;
  let groupStartTime = null;
  let lastMealTime = null;
  let timeSum = 0;
  let mealCount = 0;

  const finalizeCurrentGroup = () => {
    if (!current) {
      return;
    }
    const avgTime = Math.floor(timeSum / mealCount);
    current.average_meal_time = new Date(avgTime);
    results.push(current);
  };

  for (const meal of meals) {
    if (!current) {
      current = { ...meal };
      groupStartTime = meal.timestamp.toMillis();
      lastMealTime = meal.timestamp.toMillis();
      timeSum = meal.timestamp.toMillis();
      mealCount = 1;
      continue;
    }
    const referenceTime = chainMeals ? lastMealTime! : groupStartTime!;
    const sameMealName = meal.meal_name === current.meal_name;
    const withinWindow = meal.timestamp.toMillis() - referenceTime <= windowMilliseconds;

    if (sameMealName && withinWindow) {
      current.alcohol += meal.alcohol;
      current.carbs += meal.carbs;
      current.fat += meal.fat;
      current.protein += meal.protein;
      current.carbs_g += meal.carbs_g;
      current.meal_items.push(...meal.meal_items);
      timeSum += meal.timestamp.toMillis();
      mealCount++;
      lastMealTime = meal.timestamp.toMillis();
    } else {
      finalizeCurrentGroup();
      current = null;
      current = { ...meal };
      groupStartTime = meal.timestamp.toMillis();
      lastMealTime = meal.timestamp.toMillis();
      timeSum = meal.timestamp.toMillis();
      mealCount = 1;
    }
  }
  finalizeCurrentGroup();
  return results;
};

const trimLabel = (str) => {
  if (!str) {
    return '';
  } // Handle empty string
  if (str.length > 3) {
    return str.substring(0, 3);
  }
  return str;
  // return str.charAt(0).toUpperCase();
};

const MealCompositionChart = (props: {
  width: number,
  height: number,
  xDomainMS: [number, number],
  mealLogs: MealLog[],
  xTickFrequencyHours: number,
  date: DateTime,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [tooltipMeal, setTooltipMeal] = useState(null);
  const { patient } = useStore();
  const { t } = useTranslation();

  const handleMouseMove = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    setCoords({ x: e.clientX, y: e.clientY });
  };

  const updateTooltip = (mealData: MealCompositionMarkerData) => {
    setTooltipMeal(mealData);
  };

  const mealQuery = useQuery(
    [MACRO_NUTRIENT_QUERY_KEY, props.date],
    async () => {
      const res = await reportingApi.appApiPatientReportsApiGetPatientNutritionDetailsTable({
        patient_id: patient.patient_id,
        group_by: 'meal_id',
        filter: JSON.stringify({
          meal_date: props.date.toISODate(),
        }),
      });
      return res.data;
    },
    {
      refetchOnMount: false,
      staleTime: 60000,
    },
  );

  const meals = mealQuery.data?.rows || [];

  const mealMacros = meals.map((row) => ({
    meal_id: row.meal_id,
    alcohol: row.alcohol_g_sum * 7,
    carbs: row.carbohydrate_g_sum * 4,
    fat: row.fat_g_sum * 9,
    protein: row.protein_g_sum * 4,
    carbs_g: row.carbohydrate_g_sum,
  }));

  const mealMacrosFiltered = mealMacros.filter(
    (row) => row.alcohol + row.carbs + row.fat + row.protein > 0,
  );

  interface MealLogMapEntry {
    timestamp: DateTime;
    meal_name: string;
    meal_items: string[];
  }

  const logmap: Map<number, MealLogMapEntry> = new Map();
  props.mealLogs.forEach((log) => {
    const meal_id = log.meal_id;
    const timestamp = log.timestamp;
    const meal_name = log.meal_name;
    const meal_items = log.meal_items;
    logmap.set(meal_id, { timestamp, meal_name, meal_items });
  });

  const filteredMeals = mealMacrosFiltered
    .map((row) => {
      const log = logmap.get(row.meal_id);
      if (log !== undefined) {
        return {
          ...row,
          timestamp: log.timestamp,
          meal_name: log.meal_name,
          meal_items: [...log.meal_items],
        };
      }
      return null;
    })
    .filter((row) => row !== null);

  const segmentedMeals = segmentMeals(filteredMeals);
  const maxMealKCals = Math.max(
    ...segmentedMeals.map((row) => row.alcohol + row.carbs + row.fat + row.protein),
    1,
  );

  const yVal = (meal_name: string) => {
    switch (meal_name) {
      case 'breakfast':
        return props.height * 0.8;
      case 'lunch':
        return props.height * 0.6;
      case 'dinner':
        return props.height * 0.4;
      default:
        return props.height * 0.2;
    }
  };

  const scatterData = segmentedMeals.map((item) => ({
    x: item.average_meal_time.getTime(),
    y: yVal(item.meal_name),
    meal: item,
  }));

  const xTicks = _.range(
    props.xDomainMS[0] + 1000 * 60 * 60 * 3,
    props.xDomainMS[1],
    1000 * 60 * 60 * props.xTickFrequencyHours,
  );

  const yTicks = [
    props.height * 0.2,
    props.height * 0.4,
    props.height * 0.6,
    props.height * 0.8,
  ];
  const yTickLabel = (y: number) => {
    switch (y) {
      case props.height * 0.8:
        return trimLabel(t('Breakfast'));
      case props.height * 0.6:
        return trimLabel(t('Lunch'));
      case props.height * 0.4:
        return trimLabel(t('Dinner'));
      default:
        return trimLabel(t('Snack'));
    }
  };

  return (
    <>
      <RxVictoryChart
        domain={{ x: props.xDomainMS, y: [0, props.height] }}
        width={props.width}
        height={props.height}
        padding={{ top: 5, bottom: 30, left: 0, right: 25 }}
      >
        <VictoryAxis
          tickValues={xTicks}
          tickFormat={(x) =>
            x !== roundTimestampToMinute(x, 60)
              ? DateTime.fromSeconds(x / 1000).toFormat('h:mma')
              : DateTime.fromSeconds(x / 1000).toFormat('ha')}
          style={{
            ticks: {
              stroke: 'rgba(0,0,0,0.2)',
              size: 5,
            },
            tickLabels: {
              fontSize: 6,
              textAnchor: 'middle',
              fill: 'rgba(0,0,0,0.6)',
              padding: 5,
            },
            axisLabel: {
              fontSize: 4,
              padding: 22,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          orientation="right"
          tickValues={yTicks}
          tickFormat={yTickLabel}
          style={{
            axis: { stroke: 'transparent' },
            grid: { stroke: 'rgba(0,0,0,0.2)' },
            tickLabels: {
              fontSize: 6,
              textAnchor: 'middle',
              fill: 'rgba(0,0,0,0.6)',
              padding: 5,
            },
            axisLabel: {
              fontSize: 4,
              padding: 14,
            },
          }}
        />
        <VictoryScatter
          data={scatterData}
          dataComponent={
            <CustomMealMarker
              maxMealGrams={maxMealKCals}
              height={props.height}
              onMouseMove={handleMouseMove}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onUpdateTooltip={updateTooltip}
            />
          }
        />
        <VictoryLegend
          orientation="horizontal"
          gutter={10}
          style={{
            labels: { fontSize: 6 },
          }}
          data={[
            { name: t('Alcohol'), symbol: { fill: '#66ccff' } },
            { name: t('Carbs'), symbol: { fill: '#66cc66' } },
            { name: t('Fat'), symbol: { fill: '#ff6666' } },
            { name: t('Protein'), symbol: { fill: '#ffcc00' } },
          ]}
        />
      </RxVictoryChart>
      <MealCompositionTooltip x={coords.x} y={coords.y} meal={tooltipMeal} show={showTooltip} />
    </>
  );
};

export default MealCompositionChart;
