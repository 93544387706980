import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { useTranslation } from '../i18n';
import { HeartRateDataPoint, OxygenDataPoint } from '../services/HealthDataService';
import SpO2Tooltip from './SpO2Tooltip';

export const HR_CHART_Y_AXIS_MAX = 200;
export const HR_CHART_Y_AXIS_MED2 = 150;
export const HR_CHART_Y_AXIS_MED1 = 100;
export const HR_CHART_Y_AXIS_MIN = 50;

export const SPO2_CHART_Y_AXIS_MAX = 100;
export const SPO2_CHART_Y_AXIS_MIN = 85;

// eslint-disable-next-line i18next/no-literal-string
const hrColor = '#F15A37';
// eslint-disable-next-line i18next/no-literal-string
const spo2Color = '#528EC3';

const roundTimestampToMinute = (timestamp: number, toMinutes: number) => {
  const ms = toMinutes * 60 * 1000;
  return Math.floor(timestamp / ms) * ms;
};

export const HeartRateChart = (
  props: {
    xDomainMS: [number, number],
    xTickFrequencyHours: number,
    width: number,
    height: number,
    heartRateData: HeartRateDataPoint[],
    spo2Data: OxygenDataPoint[],
  },
) => {
  const { width, height, heartRateData, spo2Data } = props;
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [tooltipData, setTooltipData] = useState(null);

  const hasSpO2Data = spo2Data.length > 0;

  const hourInMS = 1000 * 60 * 60;
  const xTicks = _.range(
    props.xDomainMS[0] + hourInMS * 3,
    props.xDomainMS[1],
    hourInMS * props.xTickFrequencyHours,
  );

  const handleMouseMove = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    setCoords({ x: e.clientX, y: e.clientY });
  };

  const updateTooltip = (data) => {
    setTooltipData(data);
  };

  return (
    <>
      <VictoryChart
        domain={{ y: [0, 200], x: props.xDomainMS }}
        height={height}
        width={width}
        padding={{ bottom: 30, left: 0, right: 25, top: hasSpO2Data ? 20 : 5 }}
      >
        <VictoryAxis
          dependentAxis
          orientation="right"
          tickValues={[HR_CHART_Y_AXIS_MIN, HR_CHART_Y_AXIS_MED1, HR_CHART_Y_AXIS_MED2, HR_CHART_Y_AXIS_MAX]}
          tickFormat={(t) => `${t} bpm`}
          style={{
            axis: { stroke: 'none', padding: 5 },
            grid: { stroke: 'rgba(0,0,0,0.2)' },
          }}
          tickLabelComponent={
            <VictoryLabel
              dx={2}
              style={{ fontSize: 6, fill: hrColor, textAnchor: 'middle', padding: 8 }}
            />
          }
        />

        {hasSpO2Data && (
          <VictoryAxis
            dependentAxis
            orientation="left"
            tickValues={[HR_CHART_Y_AXIS_MIN, HR_CHART_Y_AXIS_MED1, HR_CHART_Y_AXIS_MED2, HR_CHART_Y_AXIS_MAX]}
            tickFormat={(t) => (t === HR_CHART_Y_AXIS_MIN
              ? '85%'
              : t === HR_CHART_Y_AXIS_MED1
              ? '90%'
              : t === HR_CHART_Y_AXIS_MED2
              ? '95%'
              : '100%')}
            style={{
              axis: { stroke: 'transparent', padding: 5 },
              grid: { stroke: 'none' },
            }}
            tickLabelComponent={
              <VictoryLabel
                dx={12}
                style={{ fontSize: 6, fill: spo2Color, textAnchor: 'start' }}
              />
            }
          />
        )}
        {hasSpO2Data && (
          <VictoryLine
            data={spo2Data}
            x="x"
            y="mappedSpo2"
            style={{ data: { stroke: spo2Color, strokeWidth: 1 } }}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseEnter: (evt, data) => {
                    setShowTooltip(true);
                    updateTooltip({
                      ...data.datum,
                      spo2: data.datum.spo2,
                    });
                    handleMouseMove(evt);
                  },
                  onMouseLeave: () => {
                    setShowTooltip(false);
                    updateTooltip(null);
                  },
                  onMouseMove: (evt) => {
                    handleMouseMove(evt);
                  },
                },
              },
            ]}
          />
        )}
        {hasSpO2Data && spo2Data.length < 3 && (
          <VictoryScatter
            data={spo2Data}
            x="x"
            y="mappedSpo2"
            size={2}
            style={{ data: { fill: spo2Color } }}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseEnter: (evt, data) => {
                    setShowTooltip(true);
                    updateTooltip({
                      ...data.datum,
                      spo2: data.datum.spo2 || data.datum.mappedSpo2,
                    });
                    handleMouseMove(evt);
                  },
                  onMouseLeave: () => {
                    setShowTooltip(false);
                    updateTooltip(null);
                  },
                  onMouseMove: (evt) => {
                    handleMouseMove(evt);
                  },
                },
              },
            ]}
          />
        )}

        <VictoryArea
          data={heartRateData}
          x="x"
          y="p75"
          y0={(d) => d.p25}
          interpolation="monotoneX"
          style={{ data: { fill: hrColor, fillOpacity: 0.2, strokeWidth: 0.4, strokeDasharray: '2,2' } }}
        />

        <VictoryLine
          data={heartRateData}
          x="x"
          y="p50"
          interpolation="monotoneX"
          style={{ data: { stroke: hrColor, strokeWidth: 0.5 } }}
        />

        <VictoryAxis
          tickValues={xTicks}
          tickFormat={(x) =>
            x !== roundTimestampToMinute(x, 60)
              ? DateTime.fromSeconds(x / 1000).toFormat('h:mma')
              : DateTime.fromSeconds(x / 1000).toFormat('ha')}
          style={{
            ticks: {
              stroke: 'rgba(0,0,0,0.2)',
              size: 5,
            },
            tickLabels: {
              fontSize: 6,
              textAnchor: 'middle',
              fill: 'rgba(0,0,0,0.6)',
              padding: 5,
            },
            axisLabel: {
              fontSize: 4,
              padding: 22,
            },
          }}
        />

        {hasSpO2Data && (
          <VictoryLegend
            orientation="horizontal"
            gutter={20}
            style={{ labels: { fontSize: 6 } }}
            data={[
              { name: t('Heart Rate'), symbol: { fill: hrColor } },
              { name: t('SpO2'), symbol: { fill: spo2Color } },
            ]}
          />
        )}
      </VictoryChart>
      <SpO2Tooltip x={coords.x} y={coords.y} data={tooltipData} show={showTooltip} />
    </>
  );
};
