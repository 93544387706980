import React from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../context';
import { Trans, useTranslation } from '../i18n';

const Resources = () => {
  const { clinician } = useStore();
  const { i18n } = useTranslation();

  // eslint-disable-next-line i18next/no-literal-string
  const knowledgeBaseLang = i18n.language === 'fr' ? 'fr-ca' : 'en';
  const iframeLang = i18n.language === 'fr' ? 'French' : 'English';

  return (
    <>
      <div style={{ padding: 10 }}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Link className="btn-link backToList" to="/patients">
          &lt; <Trans>Back to patient list</Trans>
        </Link>
      </div>
      <div style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}>
        <h2>
          <Trans>Resources</Trans>
        </h2>
      </div>
      <div style={{ paddingTop: '25px', margin: '0 auto', width: '100%', textAlign: 'center' }}>
        <a
          href={`https://clinical-support.rxfood.com/portal/${encodeURIComponent(knowledgeBaseLang)}/home`}
          target="_blank"
          style={{ color: '#007bff', textDecoration: 'underline' }}
        >
          <Trans>Knowledge Base</Trans>
        </a>
      </div>
      <iframe
        title="Zoho Support Ticket"
        src={`/zohoTicketForm.html?lang=${encodeURIComponent(iframeLang)}&user_id=${encodeURIComponent(clinician.id)}`}
        width="100%"
        height="500px"
        frameBorder="0"
        style={{ border: 'none' }}
      />
    </>
  );
};

export default Resources;
