import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { VictoryAxis, VictoryBar } from 'victory';
import { RxVictoryChart } from './RxVictory';

interface InsulinLog {
  timestamp: DateTime;
  dosage: number;
}

const roundTimestampToMinute = (timestamp: number, minutes: number): number => {
  const ms = minutes * 60 * 1000;
  return Math.floor(timestamp / ms) * ms;
};

const InsulinChart = (props: {
  width: number,
  height: number,
  xDomainMS: [number, number],
  xTickFrequencyHours: number,
  insulinLogs: InsulinLog[],
}) => {
  const hourInMS = 1000 * 60 * 60;
  const data = props.insulinLogs.map(log => ({
    x: log.timestamp,
    y: log.dosage,
  }));

  const xTicks = _.range(
    props.xDomainMS[0] + hourInMS * 3,
    props.xDomainMS[1],
    hourInMS * props.xTickFrequencyHours,
  );

  const maxDosage = data.reduce((max, d) => (d.y > max ? d.y : max), 0);
  const yDomain: [number, number] = [0, maxDosage * 1.2];

  const decimalPlaces = maxDosage >= 3 ? 0 : maxDosage >= 0.3 ? 1 : 2;

  return (
    <RxVictoryChart
      domain={{ x: props.xDomainMS, y: yDomain }}
      height={props.height}
      width={props.width}
      padding={{ top: 5, bottom: 30, left: 0, right: 25 }}
    >
      <VictoryAxis
        tickValues={xTicks}
        tickFormat={(x) =>
          x !== roundTimestampToMinute(x, 60)
            ? DateTime.fromSeconds(x / 1000).toFormat('h:mma')
            : DateTime.fromSeconds(x / 1000).toFormat('ha')}
        style={{
          ticks: { stroke: 'rgba(0,0,0,0.2)', size: 5 },
          tickLabels: {
            fontSize: 6,
            textAnchor: 'middle',
            fill: 'rgba(0,0,0,0.6)',
            padding: 5,
          },
          axisLabel: { fontSize: 4, padding: 22 },
        }}
      />
      <VictoryAxis
        dependentAxis
        orientation="right"
        height={props.height}
        tickFormat={(y) => y.toFixed(decimalPlaces)}
        tickCount={4}
        style={{
          axis: { stroke: 'transparent' },
          grid: { stroke: 'rgba(0,0,0,0.2)' },
          tickLabels: {
            fontSize: 6,
            textAnchor: 'middle',
            fill: 'rgba(0,0,0,0.6)',
            padding: 5,
          },
          axisLabel: { fontSize: 4, padding: 14 },
        }}
      />
      <VictoryBar
        data={data}
        x="x"
        y="y"
        cornerRadius={{ top: 2 }}
        style={{
          data: { fill: '#66cc66', width: 5 },
        }}
      />
    </RxVictoryChart>
  );
};

export default InsulinChart;
