import { Tooltip, withStyles } from '@material-ui/core';

export const StyledTooltip = withStyles((theme) => ({
  arrow: {
    '&:before': {
      border: '1px solid #C5C5C5',
    },
    color: '#FFFFFF',
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: 'black',
    maxWidth: 220,
    fontSize: '12.8px',
    border: '1px solid #C5C5C5',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.15)',
    padding: '7px',
    fontWeight: 400,
    fontFamily: 'geomanist',
    marginBottom: '7px',
  },
}))(Tooltip);
