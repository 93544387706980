import classnames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../context';
import logo from '../logo.svg';
import './layout.scss';
import { ANALYTICS_EVENTS, track } from '../analytics';
import { Trans, useTranslation } from '../i18n';

const PageLayout: React.FunctionComponent = (props) => {
  return <div className="pageLayout">{props.children}</div>;
};

const CenterLayout: React.FunctionComponent = (props) => {
  const [invertTheme, setInvertTheme] = useState(false);

  const classes = classnames('centerLayout', { invertPage: invertTheme });
  const el = React.cloneElement(props.children as React.ReactElement<any>, { setInvertTheme });

  return <div className={classes}>{el}</div>;
};

const LoggedIn: React.FunctionComponent = (props) => {
  const { i18n, t } = useTranslation();
  const { token } = useStore();
  if (!token) {
    window.location.href = '/login';
  }
  return (
    <div>
      <Link className="logoutBtn" to="/logout" onClick={() => track(ANALYTICS_EVENTS.LOGOUT)}>
        <button>
          <i className="fa fa-power-off" aria-hidden="true" /> <Trans>Log out</Trans>
        </button>
      </Link>
      <button className="helpBtn" style={{ marginRight: i18n.language === 'fr' ? '60px' : '0px' }}>
        <a
          href="mailto:support@rxfood.co?subject=Clinician Portal Help"
          onClick={() => track(ANALYTICS_EVENTS.HELP_CLICKED)}
        >
          <i className="fa fa-question" aria-hidden="true" title={t('Email Support')} />
        </a>
      </button>
      {
        /* <Link to="/resources" onClick={() => track(ANALYTICS_EVENTS.HELP_CLICKED)}>
        <button className="helpBtn" style={{ marginRight: i18n.language === 'fr' ? '60px' : '0px' }}>
          <i className="fa fa-question" aria-hidden="true" title={t('Resources')} />
        </button>
      </Link> */
      }

      {props.children}
    </div>
  );
};

const CenterLayoutWithLogo: React.FunctionComponent = (props) => {
  return (
    <div className="centerLayout">
      <div className="headerSection">
        <img src={logo} className="headerLogo" alt="logo" />
        <h5 className="headerTitle">
          <Trans>Rx Food</Trans>
        </h5>
      </div>
      <div className="bodySection">
        {props.children}
      </div>
    </div>
  );
};

export { CenterLayout, CenterLayoutWithLogo, LoggedIn, PageLayout };
