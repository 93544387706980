import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  MealItemResponse,
  MealResponse,
  PatientNutritionDetailsTableRow,
  UsdaNutritionResponse,
} from '../api/generated/models';
import { useCurrentPatientData, useStore } from '../context';
import { i18n, TAttrFunc, TFunc, Trans, useTranslation } from '../i18n';
import './mealCard.scss';

import _ from 'lodash';
import { nutrientGetDef } from '../pages/nutrients';
import { NutrientRowDataType } from '../pages/patient-nutrient-new';
import { FormatAMPMStr } from '../utils/formatDate';
import { formatNumber, titleCase } from '../utils/formatters';
import { EXERCISE_TYPES, getExerciseIntensityOptions } from '../utils/userDataTranslations';
import { CGMMealTimeInRange } from './cgm/CGMTimeInRange';
import { OriginalImage } from './image';
import { getMealItemsNutrientSum, mealCardNutrientValueType } from './showNutrientDetails';
import { StyledTooltip } from './StyledTooltip';
export function itemAddonsToStr(tAttr: TAttrFunc, item: MealItemResponse) {
  return (item.custom_addons || []).map(addon => tAttr(addon, 'food_name')).join(', ');
}

export type NutrientLabelDef = {
  labelTr: string, // "carbs" or "kcals", translated
  getValue: (mealItem: MealItemResponse) => number, // function to get the value from a meal item
  getValueStr: (mealItem: MealItemResponse) => string, // get rounded value as string, including "≈" if it's a custom item
  unitSuffix: string, // "g" or ""
  useLabelunderValue: boolean,
  colorize: (count: string) => string,
};

export const getNutrientLabelDefs = (t: TFunc): Partial<Record<keyof UsdaNutritionResponse, NutrientLabelDef>> => {
  return {
    energy_kcal: {
      labelTr: t('kcals'),
      getValue: mealItem => mealItem.energy_kcal,
      getValueStr: mealItem => {
        if (mealItem.custom_item) {
          if (mealItem.energy_kcal === 0 || !mealItem.energy_kcal) {
            return '≈ ...';
          }
          return '≈' + String(Math.round(mealItem.energy_kcal));
        }
        return typeof mealItem.energy_kcal === 'number' ? String(Math.round(mealItem.energy_kcal)) : '';
      },
      unitSuffix: '',
      useLabelunderValue: true,
      colorize: (count: string) => {
        if (count.startsWith('≈')) {
          return 'rgba(68, 68, 68, 0.5)';
        }
        return '#444';
      },
    },

    carbohydrate_g: {
      labelTr: t('carbs'),
      getValue: mealItem => mealItem.carbohydrate_g,
      getValueStr: mealItem => {
        if (mealItem.custom_item) {
          if (mealItem.carbohydrate_g === 0 || !mealItem.carbohydrate_g) {
            return '≈ ...';
          }
          return '≈' + String(Math.round(mealItem.carbohydrate_g));
        }
        return typeof mealItem.carbohydrate_g === 'number' ? String(Math.round(mealItem.carbohydrate_g)) : '';
      },
      unitSuffix: 'g',
      useLabelunderValue: false,
      colorize: (count: string) => {
        if (count.startsWith('≈')) {
          return 'rgba(68, 68, 68, 0.5)';
        }
        return Number(count) > 60 ? '#F15A37' : '#35A499';
      },
    },

    netcarb_g: {
      labelTr: t('net carbs'),
      getValue: mealItem => mealItem.netcarb_g,
      getValueStr: mealItem => {
        if (mealItem.custom_item) {
          if (mealItem.netcarb_g === 0 || !mealItem.netcarb_g) {
            return '≈ ...';
          }
          return '≈' + String(Math.round(mealItem.netcarb_g));
        }
        return typeof mealItem.netcarb_g === 'number' ? String(Math.round(mealItem.netcarb_g)) : '';
      },
      unitSuffix: 'g',
      useLabelunderValue: false,
      colorize: (count: string) => {
        if (count.startsWith('≈')) {
          return 'rgba(68, 68, 68, 0.5)';
        }
        return Number(count) > 60 ? '#F15A37' : '#35A499';
      },
    },

    protein_g: {
      labelTr: t('protein'),
      getValue: mealItem => mealItem.protein_g,
      getValueStr: mealItem => String(Math.round(mealItem.protein_g)),
      unitSuffix: 'g',
      useLabelunderValue: false,
      colorize: (count: string) => '#444',
    },

    fat_g: {
      labelTr: t('fats'),
      getValue: mealItem => mealItem.fat_g,
      getValueStr: mealItem => String(Math.round(mealItem.fat_g)),
      unitSuffix: 'g',
      useLabelunderValue: false,
      colorize: (count: string) => '#444',
    },

    fiber_g: {
      labelTr: t('fibre'),
      getValue: mealItem => mealItem.fiber_g,
      getValueStr: mealItem => String(Math.round(mealItem.fiber_g)),
      unitSuffix: 'g',
      useLabelunderValue: false,
      colorize: (count: string) => '#444',
    },
  };
};

/**
 * Returns the macro - carbs or cals - to show next to meal items, along with
 * relevant formatting and labelling information.
 */
export function useCarbsCals(): null | NutrientLabelDef {
  const { flags } = useCurrentPatientData();
  const { t } = useTranslation();
  const labelDefs = getNutrientLabelDefs(t);

  if (flags?.patient_show_cals) {
    return labelDefs.energy_kcal;
  }

  if (flags?.patient_show_total_carbs) {
    return labelDefs.carbohydrate_g;
  }

  return labelDefs.netcarb_g;
}

export function mealItemIsVerifying(mealItem: MealItemResponse) {
  return mealItem.food_name == 'awaiting verification...';
}

export const MealNutrient = (props: {
  meals: MealResponse[],
  selectedNutrient: NutrientRowDataType,
  nutrientVal: mealCardNutrientValueType,
}) => {
  const count = _.sum(props.meals.map(meal => getMealItemsNutrientSum(meal, props.selectedNutrient)))
    ?? props.nutrientVal?.[props.selectedNutrient.field];
  const nutrientDef = nutrientGetDef(props.selectedNutrient.field as keyof PatientNutritionDetailsTableRow);
  const formattedVal = nutrientDef.format(count);

  return (
    <div className="float-right d-inline-block" style={{ color: '#444' }}>
      <h6 className="m-0 text-right carbs-cals-count">
        {formattedVal}
      </h6>
      <small className="float-right">{nutrientDef.suffix || ''}</small>
    </div>
  );
};

export const MealCarbsCals = ({ meals }: {
  meals: MealResponse[],
}) => {
  const macroToShow = useCarbsCals();
  const carbsCals = React.useMemo(() => {
    if (!macroToShow) {
      return false;
    }
    const isVerifying = !!meals.every(meal => meal?.meal_items.find(mealItemIsVerifying));
    if (isVerifying) {
      return false;
    }
    if (!macroToShow) {
      return false;
    }
    let itemCount = 0;
    let count = 0;
    let customItemCount = 0;
    meals.forEach(meal => {
      meal?.meal_items.map(item => {
        itemCount += 1;
        count += macroToShow.getValue(item);
        if (item.custom_item) {
          customItemCount += 1;
        }
      });
    });
    if (itemCount == 0) {
      return false;
    }
    count = Math.round(count);
    // eslint-disable-next-line i18next/no-literal-string
    const countStr = (customItemCount > 0) ? '≈' + String(count) : String(count);
    const color = macroToShow.colorize(countStr);
    return {
      countStr,
      count,
      color,
    };
  }, [macroToShow, meals]);

  if (!macroToShow || !carbsCals) {
    return null;
  }

  const customMealItemCount = _.sum(meals.map(meal => _.sum(meal.meal_items.map(item => item.custom_item ? 1 : 0))));

  return (
    <StyledTooltip
      placement="top"
      arrow
      title={customMealItemCount
        ? (
          <>
            <div>
              <Trans>This value is an estimate.</Trans>
            </div>
            <div>
              <Trans>
                Foods logged on this day have food flagged for verification, please check back soon for an update.
              </Trans>
            </div>
          </>
        )
        : ''}
    >
      <div className="float-right d-inline-block" style={{ color: carbsCals.color }}>
        <h6 className="mb-0 text-right carbs-cals-count">
          {carbsCals.countStr + macroToShow.unitSuffix}
        </h6>
        <small>{macroToShow.labelTr}</small>
      </div>
    </StyledTooltip>
  );
};

export const MealItemNutrient = (props: {
  mealItem: MealItemResponse,
  selectedNutrient: NutrientRowDataType,
}) => {
  const count = props.mealItem[props.selectedNutrient.field];
  const nutrientDef = nutrientGetDef(props.selectedNutrient.field as keyof PatientNutritionDetailsTableRow);
  const formattedVal = nutrientDef.format(count);

  return (
    <div className="float-right d-inline-block" style={{ color: '#444' }}>
      <h6 className="m-0 text-right carbs-cals-count">
        {formattedVal}
      </h6>
      <small className="float-right">{nutrientDef.suffix || ''}</small>
    </div>
  );
};

export const MealItemCarbsCals = (props: {
  mealItem: MealItemResponse,
}) => {
  const macroToShow = useCarbsCals();
  const carbsCals = React.useMemo(() => {
    if (!macroToShow) {
      return false;
    }
    const isVerifying = mealItemIsVerifying(props.mealItem);
    if (isVerifying) {
      return false;
    }
    if (!macroToShow) {
      return false;
    }
    const count = Math.round(macroToShow.getValue(props.mealItem));
    const countStr = macroToShow.getValueStr(props.mealItem);
    const color = macroToShow.colorize(countStr);
    return {
      countStr,
      count,
      color,
    };
  }, [macroToShow, props.mealItem]);

  if (!macroToShow || !carbsCals) {
    return null;
  }
  return (
    <div className="float-right d-inline-block" style={{ color: carbsCals.color }}>
      <h6 className="m-0 text-right carbs-cals-count">
        {carbsCals.countStr}
        {macroToShow.unitSuffix}
      </h6>
      {macroToShow.labelTr}
    </div>
  );
};

export const MealItemMacros = (props: {
  mealItem: MealItemResponse,
}) => {
  const { t } = useTranslation();
  const labelDefs = getNutrientLabelDefs(t);

  const nutrients: (keyof PatientNutritionDetailsTableRow)[] = [
    'protein_g',
    'fat_g',
    'fiber_g',
    'energy_kcal',
    'carbohydrate_g',
    'netcarb_g',
  ];

  const mealItemHasOverrides = props.mealItem.custom_addons?.some(ca => ca.nutrient_overrides?.aa_patient)
    || props.mealItem.nutrient_overrides?.aa_patient;

  if (mealItemIsVerifying(props.mealItem) || nutrients.every(nutrient => !props.mealItem[nutrient])) {
    return null;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '14px' }}>
        {labelDefs && nutrients.map(nutrient => {
          const val = labelDefs[nutrient].getValueStr(props.mealItem);
          return (
            <MealItemMacro
              key={nutrient}
              value={val}
              label={labelDefs[nutrient].labelTr}
              unit={labelDefs[nutrient].unitSuffix}
              color={labelDefs[nutrient].colorize(val)}
            />
          );
        })}
      </div>
      {mealItemHasOverrides && (
        <div
          style={{ display: 'flex', gap: '8px', fontStyle: 'italic', color: '#666666', fontSize: '14px' }}
        >
          <div style={{ fontWeight: 'bold' }}>
            <Trans>!</Trans>
          </div>
          <Trans>The patient has customized these values.</Trans>
        </div>
      )}
    </div>
  );
};

const MealItemMacro = (props: {
  value: string,
  label?: string,
  unit?: string,
  color?: string,
}) => {
  return (
    <div className="float-right d-inline-block" style={{ color: props.color || '#444' }}>
      <h6 className="m-0 text-right carbs-cals-count">
        {props.value}
        {props.unit ? `${props.unit}` : ''}
      </h6>
      {props.label ? <small>{props.label}</small> : null}
    </div>
  );
};

export function MealItemPercentEaten(props: { item: MealItemResponse }) {
  return props.item.percent_eaten === 1
    ? null
    : (
      <>
        <Trans>{{ percent_eaten: formatNumber(i18n.language, props.item.percent_eaten / 1 * 100, 0) }}% of</Trans>
        {' '}
      </>
    );
}

export const MealCard = (props: {
  meal: MealResponse,
  selectedNutrient?: NutrientRowDataType,
  nutrientVal?: mealCardNutrientValueType,
  width?: string,
}) => {
  const { meal, selectedNutrient, nutrientVal } = props;
  const { patient } = useStore();
  const { tAttr, t } = useTranslation();

  return (
    <div className="meal-item m-2">
      <div className="meal-card" style={{ width: props.width || '400px' }}>
        <Row>
          <Col>
            {selectedNutrient && (
              <div className="d-inline-block" style={{ marginRight: '5px' }}>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {`${meal?.meal_name} on ${meal?.meal_date.slice(5, 10)} at`}
              </div>
            )}
            <div className="d-inline-block">
              {FormatAMPMStr(meal?.meal_time)}
            </div>
            {!selectedNutrient
              ? <MealCarbsCals meals={[meal]} />
              : <MealNutrient meals={[meal]} nutrientVal={nutrientVal} selectedNutrient={selectedNutrient} />}
          </Col>
        </Row>
        {!!meal.meal_photo_resized_url && (
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <OriginalImage
                mealPhotoId={meal.meal_photo_id}
                style={{
                  width: '100%',
                  height: 150,
                  objectFit: 'cover',
                }}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {meal.meal_items.map((mealItem, i) => {
              const addonsStr = itemAddonsToStr(tAttr, mealItem);
              return (
                <div key={i} style={{ marginBottom: 8 }}>
                  {!selectedNutrient
                    ? <MealItemCarbsCals mealItem={mealItem} />
                    : <MealItemNutrient mealItem={mealItem} selectedNutrient={selectedNutrient} />}
                  <span
                    className="text-truncate"
                    style={{
                      display: 'block',
                      fontWeight: 600,
                      marginBottom: -5,
                      paddingRight: 5,
                    }}
                  >
                    {titleCase(tAttr(mealItem, 'food_name'))}
                  </span>
                  {!mealItemIsVerifying(mealItem) && (
                    <div style={{ color: '#999', paddingRight: 5 }} className="text-truncate">
                      <MealItemPercentEaten item={mealItem} />
                      {mealItem.servings} {tAttr(mealItem, 'serving_unit_label')}
                      {addonsStr && (' ' + t(`with`) + ' ' + addonsStr)}
                    </div>
                  )}
                </div>
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col>
            {/* TODO: need to add functionality for CGM oauth */}
            <CGMMealTimeInRange
              patient_id={patient.patient_id}
              meal_date={meal.meal_date}
              meal_time={meal.meal_time}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const MedicationCard = (props) => {
  const { medication } = props;
  return (
    <div className="d-inline-block m-1">
      <div className="meal-item m-2">
        <div className="activity-card">
          <Row>
            <Col>
              <div className="pl-0">
                <div className="d-inline-block">
                  <div>{FormatAMPMStr(medication.medication_time)}</div>
                  <span style={{ fontWeight: 600 }}>{medication.quantity} {medication.unit}</span>, {medication.name}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export const ExerciseCard = (props) => {
  const { exercise } = props;
  const { t } = useTranslation();

  const exerciseIntensity = getExerciseIntensityOptions(t);
  return (
    <div className="d-inline-block m-1">
      <div className="meal-item m-2">
        <div className="activity-card">
          <Row>
            <Col>
              <div className="pl-0">
                <div className="d-inline-block">
                  <div>{FormatAMPMStr(exercise.exercise_time)}</div>
                  <span style={{ fontWeight: 600 }}>
                    {exercise.duration_min} <Trans>minutes</Trans>
                  </span>,{' '}
                  <Trans>
                    {{ exerciseIntensity: exerciseIntensity.find(type => type.id === exercise.intensity).labelTr }}{' '}
                    intensity
                  </Trans>{' '}
                  {EXERCISE_TYPES[exercise.exercise_type]?.getLabel(t) || exercise.exercise_type}
                </div>
                {exercise.note && (
                  <div style={{ color: '#999', paddingRight: 5 }}>
                    <Trans>Note</Trans>: {exercise.note}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export const EmotionCard = (props) => {
  const { emotion } = props;
  const { t } = useTranslation();

  return (
    <div className="d-inline-block m-1">
      <div className="meal-item m-2">
        <div className="activity-card">
          <Row>
            <Col>
              <div className="pl-0">
                <div className="d-inline-block">
                  <div>{FormatAMPMStr(emotion.emotion_time)}</div>
                  <span style={{ fontWeight: 600, textTransform: 'capitalize' }}>
                    {t(emotion.emotion)}
                  </span>
                </div>
                {!!emotion.note && (
                  <div style={{ color: '#999', paddingRight: 5 }}>
                    <Trans>Note</Trans>: {emotion.note}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
