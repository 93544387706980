import emailValidator from 'email-validator';
import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Input } from '../components/input';

import profileError from '../profile-error.svg';
import profileImage from '../profile.svg';

import { Trans, useTranslation } from '../i18n';
import { AuthService } from '../services/auth';

enum FIELD {
  email = 1 << 0,
  password = 1 << 1,
  bio = 1 << 2,
  picture = 1 << 3,
}

const Signup: React.FunctionComponent = (props) => {
  const auth = AuthService();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [bio, setBio] = useState('');
  const [picture, setPicture] = useState('');
  const [error, setError] = useState({ email: '', password: '', bio: '', picture: '' });
  const { t } = useTranslation();
  const validate = (fields) => {
    const newerror = { ...error };

    if (fields & FIELD.email) {
      newerror.email = '';
      if (!email) {
        newerror.email = t('Please enter your email address');
      } else if (!emailValidator.validate(email)) {
        newerror.email = t('That email is invalid');
      }
    }

    if (fields & FIELD.password) {
      newerror.password = '';
      if (!password) {
        newerror.password = t('That password is invalid');
      }
    }

    if (fields & FIELD.bio) {
      newerror.bio = '';
      if (!bio) {
        newerror.bio = t('A short bio is mandatory');
      }
    }

    if (fields & FIELD.picture) {
      newerror.picture = '';
      if (!picture) {
        newerror.picture = t('profile picture is mandatory');
      }
    }

    setError(newerror);
    return !error.email && !error.password && !error.bio;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const handleRegister = async () => {
    if (!validate(FIELD.email | FIELD.password | FIELD.bio | FIELD.picture)) {
      return false;
    }

    const { success } = await auth.authenticate(email, password);
    if (success) {
      return true;
    }
    return false;
  };

  const profile = error.picture ? profileError : profileImage;
  return (
    <Container>
      <Row>
        <Col />
        <Col>
          <h3 className="margin-bot-l text-center">
            <Trans>Register Rx Food</Trans>
          </h3>
          <img src={profile} className="profile-picture" alt="upload profile photo" />
          <Form>
            <Input
              controlId="formBasicEmail"
              type="email"
              label={t('EMAIL')}
              background
              size="lg"
              error={error.email}
              value={email}
              onChange={handleEmailChange}
              onBlur={() => validate(FIELD.email)}
            />
            <Input
              controlId="formBasicPassword"
              type="password"
              label={t('PASSWORD')}
              background
              size="lg"
              error={error.password}
              value={password}
              onChange={handlePasswordChange}
              onBlur={() => validate(FIELD.password)}
            />
            <Input
              type="textarea"
              className="short-bio"
              label={t('SHORT BIO (MAX 300 CHARACTERS)')}
              background
              size="lg"
              error={error.bio}
              value={bio}
              onChange={handleBioChange}
              onBlur={() => validate(FIELD.bio)}
            />
            <p className="margin-bot-m">
              <Trans>
                By registering you agree to RxFood's{' '}
                <Link className="btn-link" to="https://rxfood.co/privacy-policy">Privacy policy</Link> and{' '}
                <Link className="btn-link" to="https://rxfood.co/terms-and-conditions">terms & conditions</Link>
              </Trans>
            </p>
            <p className="margin-bot-l">
              <Button variant="primary" block className="margin-bot-l" onClick={handleRegister}>
                <Trans>Register</Trans>
              </Button>
            </p>
          </Form>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col />
      </Row>
    </Container>
  );
};

export default Signup;
