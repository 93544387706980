import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import frCA from 'antd/es/locale/fr_CA';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from '../i18n';
import 'react-day-picker/lib/style.css';
import './foodFilter.scss';
import { DateTime } from 'luxon';
import { FilterType, RangePickerWithActivityIndicators } from './foodFilterNew';
import { useCalendarActivityIndicators } from './useCalendarActivityFeed';

export const useDateRange = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const startQuery = searchParams.get('start');
  const endQuery = searchParams.get('end');

  let startDate = startQuery ? moment(startQuery, 'YYYY-MM-DD') : null;
  let endDate = endQuery ? moment(endQuery, 'YYYY-MM-DD') : null;

  if (!startDate && !endDate) {
    startDate = moment().subtract(1, 'month').startOf('day');
    endDate = moment().endOf('day');
  } else if (!startDate && endDate) {
    startDate = endDate.clone().subtract(1, 'month').startOf('day');
  } else if (startDate && !endDate) {
    endDate = moment().endOf('day');
  }

  if (startDate.isAfter(endDate)) {
    [startDate, endDate] = [endDate, startDate];
  }

  if (startDate.isAfter(moment().add(1, 'day').endOf('day')) || endDate.isAfter(moment().add(1, 'day').endOf('day'))) {
    startDate = moment().subtract(1, 'month').startOf('day');
    endDate = moment().add(1, 'day').endOf('day');
  }

  return [startDate, endDate];
};

export const withFilterPanel = (WrappedComponent) => {
  return withRouter(function(props) {
    const { i18n, t } = useTranslation();

    const [startDate, endDate] = useDateRange();

    const [dateRangeFilter, setDateRangeFilter] = useState<FilterType>({
      rangePickerValue: [startDate, endDate],
      dateRanges: [],
    });

    useEffect(() => {
      const [start, end] = dateRangeFilter.rangePickerValue;
      const params = new URLSearchParams(props.location.search);
      // eslint-disable-next-line i18next/no-literal-string
      params.set('start', start.format('YYYY-MM-DD'));
      // eslint-disable-next-line i18next/no-literal-string
      params.set('end', end.format('YYYY-MM-DD'));
      props.history.replace({
        pathname: props.location.pathname,
        search: params.toString(),
      });
    }, [dateRangeFilter, props.history, props.location.pathname, props.location.search]);

    const [dates, setDates] = useState({
      oldestDate: DateTime.local().minus({ days: 65 }),
      newestDate: null,
    });
    const { calendarActivityIndicators, isFetching } = useCalendarActivityIndicators(dates);

    return (
      <>
        <Row className="logFilterPanel">
          <Col className="logFilterCol">
            <span className="mr-5">
              <Trans>filter by date</Trans>
            </span>
            <ConfigProvider
              locale={i18n.language === 'fr' ? frCA : enUS}
              theme={{
                components: {
                  DatePicker: {
                    cellHeight: 32,
                    cellWidth: 32,
                  },
                },
              }}
            >
              <RangePickerWithActivityIndicators
                calendarActivityIndicators={calendarActivityIndicators}
                isFetching={isFetching}
                filter={dateRangeFilter}
                dates={dates}
                setFilter={setDateRangeFilter}
                setDates={setDates}
              />
            </ConfigProvider>
          </Col>
        </Row>

        <WrappedComponent since={dateRangeFilter.rangePickerValue[0]} until={dateRangeFilter.rangePickerValue[1]} />
      </>
    );
  });
};
