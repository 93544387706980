import { DateTime } from 'luxon';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from '../i18n';

interface SpO2TooltipProps {
  x: number;
  y: number;
  data: {
    x: number,
    spo2: number,
  };
  show: boolean;
}

const SpO2Tooltip = (props: SpO2TooltipProps) => {
  const { x, y, data, show } = props;
  const offset = 10;
  const tooltipRef = useRef(null);
  const [position, setPosition] = useState({ left: x + offset, top: y + offset });
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.getBoundingClientRect().height;
      let top = y + offset;
      const bottomThreshold = window.innerHeight - (tooltipHeight + offset);
      if (y > bottomThreshold) {
        top = y - tooltipHeight - offset;
      }
      setPosition({ left: x + offset, top });
    }
  }, [x, y, data]);

  if (!show || !data) {
    return null;
  }

  const time = DateTime.fromMillis(data.x).toFormat('h:mma');
  const spo2Value = Math.round(data.spo2);

  return (
    <div
      ref={tooltipRef}
      style={{
        position: 'fixed',
        left: position.left,
        top: position.top,
        background: 'rgba(255, 255, 255, 0.8)',
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        zIndex: 1,
        fontSize: '12px',
      }}
    >
      <div>
        <div style={{ fontWeight: 'bold' }}>
          {time}
        </div>
        <div>
          {t('SpO2: {{ spo2Value }}%', { spo2Value })}
        </div>
      </div>
    </div>
  );
};

export default SpO2Tooltip;
