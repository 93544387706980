import * as d3 from 'd3';
import React from 'react';
import { VictoryPie } from 'victory';

export interface MealCompositionMarkerData {
  alcohol: number;
  carbs: number;
  fat: number;
  protein: number;
  carbs_g: number;
  meal_items: string[];
}

interface MarkerProps {
  meal: MealCompositionMarkerData;
  graphHeight: number;
  maxMealGrams: number;
  onMouseMove: (e: React.MouseEvent<SVGGElement, MouseEvent>) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onUpdateTooltip: (meal: MarkerProps['meal']) => void;
}

const MealCompositionMarker = (props: MarkerProps) => {
  const { meal, graphHeight, maxMealGrams, onMouseMove, onMouseEnter, onMouseLeave, onUpdateTooltip } = props;
  const { alcohol, carbs, fat, protein, carbs_g, meal_items } = meal;
  const total = alcohol + carbs + fat + protein;

  const handleMouseMove = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    onMouseMove(e);
    onUpdateTooltip(meal);
  };

  const pieData = [
    { x: 'Alcohol', y: alcohol },
    { x: 'Carbs', y: carbs },
    { x: 'Fat', y: fat },
    { x: 'Protein', y: protein },
  ];

  const minRadius = graphHeight * 0.04;
  const maxRadius = graphHeight * 0.075;
  const radiusScale = d3.scaleLinear().domain([0, maxMealGrams]).range([minRadius, maxRadius]);
  const radius = radiusScale(total);

  return (
    <g
      onMouseMove={handleMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      transform={`translate(${-radius}, ${-radius})`}
    >
      <VictoryPie
        data={pieData}
        width={radius * 2}
        height={radius * 2}
        radius={radius}
        standalone={false}
        labels={() => null}
        colorScale={['#66ccff', '#66cc66', '#ff6666', '#ffcc00']}
      />
      <text
        x={radius}
        y={radius}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: '7px', fill: '#000' }}
      >
        {Math.round(carbs_g)}
      </text>
    </g>
  );
};

export default MealCompositionMarker;
