import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import './App.css';

import mixpanel from 'mixpanel-browser';
import { useAsyncResult } from 'react-use-async-result';
import { QueryParamProvider } from 'use-query-params';
import { ApiAuthSync, oauthApi } from './api';
import { LoadingSpinner } from './components/loadingSpinner';
import Notification from './components/notification';
import { StoreProvider, useStoreDispatch } from './context';
import { UserDefaultLanguageHandler } from './i18n';
import { CenterLayout, CenterLayoutWithLogo, LoggedIn, PageLayout } from './layout/layout';
import ForgotPassword from './pages/forgotpassword';
import Login from './pages/login';
import { PageNotFound } from './pages/PageNotFound';
import Patient from './pages/patient';
import PatientList from './pages/patientlist';
import Resources from './pages/resources';
import Signup from './pages/signup';
import { ReportCGM } from './reports/ReportCGM';

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'geomanist',
    },
  },
  palette: {
    primary: {
      main: '#36C2B4',
    },
  },
});

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <UserDefaultLanguageHandler />
          <ApiAuthSync />
          <Notification />
          <AppRoutes />
        </StoreProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

const LogoutPage = () => {
  const { reset } = useStoreDispatch();
  const logoutRes = useAsyncResult(() => {
    return oauthApi.appApiOauthLogout();
  });

  if (logoutRes.isPending) {
    return <LoadingSpinner />;
  }
  reset();

  return <Redirect to="/login" />;
};

const AppRoutes = () => {
  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <Switch>
          <Route
            path="/"
            exact
            render={() => (
              <CenterLayout>
                <Login />
              </CenterLayout>
            )}
          />
          <Route
            path="/login"
            exact
            render={() => (
              <CenterLayout>
                <Login />
              </CenterLayout>
            )}
          />
          <Route
            path="/logout"
            exact
            component={LogoutPage}
          />
          <Route
            path="/signup"
            exact
            render={() => (
              <CenterLayoutWithLogo>
                <Signup />
              </CenterLayoutWithLogo>
            )}
          />
          <Route
            path="/patients/reports/cgm/:patientId"
            render={props => <ReportCGM patientId={props.match.params.patientId} />}
          />
          <Route
            path="/patients"
            render={() => (
              <LoggedIn>
                <PageLayout>
                  <PatientList />
                </PageLayout>
              </LoggedIn>
            )}
          />
          <Route
            path="/forgotpassword"
            exact
            render={() => (
              <CenterLayout>
                <ForgotPassword />
              </CenterLayout>
            )}
          />
          <Route path="/patient" exact>
            <Redirect to="/patients" />
          </Route>
          <Route
            path="/patient/:patientIdStr"
            render={({ match }) => (
              <LoggedIn>
                <PageLayout>
                  <Patient match={match} />
                </PageLayout>
              </LoggedIn>
            )}
          />
          <Route
            path="/resources"
            exact
            render={() => (
              <LoggedIn>
                <PageLayout>
                  <Resources />
                </PageLayout>
              </LoggedIn>
            )}
          />
          <Route
            path="*"
            render={() => (
              <CenterLayout>
                <PageNotFound />
              </CenterLayout>
            )}
          />
        </Switch>
      </QueryParamProvider>
    </Router>
  );
};
