import { t } from 'i18next';
import { PatientNutritionDetailsTableRow } from '../api/generated/models';

type _NutrientDef = {
  label: string,
  nutrient: keyof PatientNutritionDetailsTableRow | 'default_carbs_g' | 'water_cups',
  suffix: string,
  decimals: number,
  isUnknown?: boolean,
};

export type NutrientDef = _NutrientDef & {
  format: (value: number) => string,
};

export const nutrientDefs: Partial<
  Record<keyof PatientNutritionDetailsTableRow | 'default_carbs_g' | 'water_cups', _NutrientDef>
> = {
  'carbohydrate_g': {
    label: t('carbohydrates'),
    nutrient: 'carbohydrate_g',
    suffix: 'g',
    decimals: 1,
  },
  'added_sugars_g': {
    label: t('added sugars'),
    nutrient: 'added_sugars_g',
    suffix: 'g',
    decimals: 1,
  },
  'fiber_g': {
    label: t('fibre'),
    nutrient: 'fiber_g',
    suffix: 'g',
    decimals: 1,
  },
  'polyols_g': {
    label: t('polyols'),
    nutrient: 'polyols_g',
    suffix: 'g',
    decimals: 1,
  },
  'netcarb_g': {
    label: t('net carbs'),
    nutrient: 'netcarb_g',
    suffix: 'g',
    decimals: 1,
  },
  'default_carbs_g': {
    label: t('carbohydrates'),
    nutrient: 'default_carbs_g' as any,
    suffix: 'g',
    decimals: 0,
  },
  'protein_g': {
    label: t('protein'),
    nutrient: 'protein_g',
    suffix: 'g',
    decimals: 1,
  },
  'fat_g': {
    label: t('fat'),
    nutrient: 'fat_g',
    suffix: 'g',
    decimals: 1,
  },
  'fattyacids_totalsaturated_g': {
    label: t('saturated fat'),
    nutrient: 'fattyacids_totalsaturated_g',
    suffix: 'g',
    decimals: 1,
  },
  'energy_kcal': {
    label: t('calories'),
    nutrient: 'energy_kcal',
    suffix: 'kcal',
    decimals: 0,
  },
  'sodium_mg': {
    label: t('sodium'),
    nutrient: 'sodium_mg',
    suffix: 'mg',
    decimals: 0,
  },
  'calcium_mg': {
    label: t('calcium'),
    nutrient: 'calcium_mg',
    suffix: 'mg',
    decimals: 0,
  },
  'iron_mg': {
    label: t('iron'),
    nutrient: 'iron_mg',
    suffix: 'mg',
    decimals: 0,
  },
  'potassium_mg': {
    label: t('potassium'),
    nutrient: 'potassium_mg',
    suffix: 'mg',
    decimals: 0,
  },
  'magnesium_mg': {
    label: t('magnesium'),
    nutrient: 'magnesium_mg',
    suffix: 'mg',
    decimals: 0,
  },
  'zinc_mg': {
    label: t('zinc'),
    nutrient: 'zinc_mg',
    suffix: 'mg',
    decimals: 0,
  },
  'phosphorus_mg': {
    label: t('phosphorus'),
    nutrient: 'phosphorus_mg',
    suffix: 'mg',
    decimals: 0,
  },
  'vitamin_b12_ug': {
    label: t('vitamin b12'),
    nutrient: 'vitamin_b12_ug',
    suffix: 'ug',
    decimals: 0,
  },
  'vitamin_d_iu': {
    label: t('vitamin d'),
    nutrient: 'vitamin_d_iu',
    suffix: 'iu',
    decimals: 0,
  },
  'folate_dfe_ug': {
    label: t('folate'),
    nutrient: 'folate_dfe_ug',
    suffix: 'ug',
    decimals: 0,
  },
  'water_g': {
    label: t('water'),
    nutrient: 'water_g',
    suffix: 'g',
    decimals: 0,
  },
  'meal_count': {
    label: t('number of logs'),
    nutrient: 'meal_count',
    suffix: '',
    decimals: 0,
  },
  'meal_date_count': {
    label: t('number of meals'),
    nutrient: 'meal_date_count',
    suffix: '',
    decimals: 0,
  },
  'cc_fish': {
    label: t('fish frequency'),
    nutrient: 'cc_fish',
    suffix: '%',
    decimals: 2,
  },
  'cc_redmeat': {
    label: t('red meat frequency'),
    nutrient: 'cc_redmeat',
    suffix: '%',
    decimals: 2,
  },
  'cc_protein': {
    label: t('protein frequency'),
    nutrient: 'cc_protein',
    suffix: '%',
    decimals: 2,
  },
  'cc_fried': {
    label: t('fried food frequency'),
    nutrient: 'cc_fried',
    suffix: '%',
    decimals: 2,
  },
  'cc_grains': {
    label: t('grains frequency'),
    nutrient: 'cc_grains',
    suffix: '%',
    decimals: 2,
  },
  'cc_processed': {
    label: t('processed food frequency'),
    nutrient: 'cc_processed',
    suffix: '%',
    decimals: 2,
  },
  'cc_ultraprocessed': {
    label: t('ultra processed food frequency'),
    nutrient: 'cc_ultraprocessed',
    suffix: '%',
    decimals: 2,
  },
  'water_cups': {
    label: t('water'),
    nutrient: 'water_cups',
    suffix: 'cups',
    decimals: 1,
  },
};

export const NUTRIENT_TABLE_ROWS = [
  'meal_date_count',
  'energy_kcal',
  'carbohydrate_g',
  'fat_g',
  'fattyacids_totalsaturated_g',
  'protein_g',
  'sodium_mg',
  'fiber_g',
  'calcium_mg',
  'iron_mg',
  'potassium_mg',
  'magnesium_mg',
  'zinc_mg',
  'phosphorus_mg',
  'vitamin_b12_ug',
  'vitamin_d_iu',
  'folate_dfe_ug',
  'added_sugars_g',
  'cc_fish',
  'cc_redmeat',
  'cc_protein',
  'cc_fried',
  'cc_grains',
  'cc_processed',
  'cc_ultraprocessed',
  'water_cups',
] as const;

/**
 * Get a nutrient's definition
 * > nutrientGetDef('energy_kcal')
 * NutrientDef object
 */
export const nutrientGetDef = (nutrient: keyof PatientNutritionDetailsTableRow | 'water_cups'): NutrientDef => {
  const _res: _NutrientDef = nutrientDefs[nutrient] ?? {
    label: nutrient,
    nutrient,
    suffix: '?',
    decimals: 0,
    isUnknown: true,
  };

  const res = _res as NutrientDef;
  if (!res.format) {
    res.format = (value: number) => {
      if (typeof value !== 'number' || Number.isNaN(value)) {
        return '–';
      }
      return `${value.toFixed(res.decimals)}`;
    };
  }

  return res;
};

/**
 * Format a nutrient's value.
 * > nutrientFormat('energy_kcal', 100.123)
 * '100 kcal'
 */
export const nutrientFormat = (nutrient: keyof PatientNutritionDetailsTableRow, value: number): string => {
  const def = nutrientGetDef(nutrient);
  return def.format(value);
};
